"use client"

import "./page.css";
import { useNavigate } from "react-router-dom";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";

function Inquire() {

    const navigate = useNavigate();

    return (
        <>
            <Header />
            <div className="InquireBox">

                {/* 상단 */}
                <div className="InquireTitle">
                    <div className="InquireTitleInner">
                        <p className="InquireTitleFirst">문의하기</p>
                        <p className="InquireTitleSecond"></p>
                        <div className="InquireTitleThird">
                        </div>
                    </div>
                </div>

                {/* 카카오 */}
                <div className="KakaoBox">
                    <div className="KakaoInnerBox">
                        <div className="KakaoInnerFirst">
                            <p className="KakaoInnerFirstIntro">안녕하세요</p>
                            <p className="KakaoInnerFirstContens">1:1문의는<br />카카오톡 채널로 하실 수 있습니다.</p>
                        </div>
                        <div className="KakaoInnerSecond">
                            <div className="KakaoLocateBox" onClick={() => window.location.href = 'http://pf.kakao.com/_Zpihxj/chat'}>
                                <img className="kakaoImage" src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Customer/Inquire_kakao.png" alt="kakao icon" />
                                <div className="KakaoLocateInner">
                                    <p className="KakaoLocateInnerText">스크랩마켓채널 바로가기</p>
                                    <img src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Customer/Inquire_kakao_plus.png" width={20} height={20} alt="kakao plus" />
                                </div>
                            </div>
                            <div className="KaokaoSearch">
                                <p style={{ color: "rgba(102, 102, 102, 1)" }}>또는 카카오톡 채널에서 “</p>
                                <p style={{ color: "rgba(0, 148, 6, 1)" }}>스크랩마켓</p>
                                <p style={{ color: "rgba(102, 102, 102, 1)" }}>” 검색!</p>
                            </div>

                        </div>
                    </div>

                </div>

                {/* 운영시간 */}
                <div className="TimeBox">
                    <div className="TimeInnerBox">
                        <div style={{ display:"flex" , flexDirection:"row" , justifyContent:"center" , alignItems:"center"}}>
                            <p style={{ fontSize: "16px", fontWeight: "500" }} >운영시간</p>
                            <p style={{ fontSize: "14px", fontWeight: "500", marginLeft: "20px" }} >평일 10:00 ~ 17:00 (주말&공휴일 제외)</p>
                            </div>
                        <div style={{ display:"flex" , flexDirection:"row" , margin:"25px 0px 60px 0px" , fontSize:"14px"}}>
                            <p>회원정보 수정 (개명 등 직접 수정할 수 없는 정보)<br/>
                                및 회원 탈퇴 문의는 스크랩마켓 채널로 주시면<br/>
                                인증절차 후 진행해드리겠습니다. </p>
                        </div>
                    </div>
                </div>

                {/* 이메일 */}
                <div className="EmailBox">
                    <div className="EmailInnerBox">
                        <p style={{ fontSize: "14px", fontWeight: "400" }}>카카오톡 이용이 어려우실 경우, 이메일 문의도 가능합니다.</p>
                        <div className="EmailInnerSecond">
                            <img src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Customer/Inquire_email.png" alt="email" width={60} height={60} />
                            <p style={{ fontSize: "15px", fontWeight: "700" }}>이메일</p>
                            <p style={{ fontSize: "15px", fontWeight: "500", color: "rgba(22, 173, 76, 1)" }}>cs@ugst.co.kr</p>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    );
}

export default Inquire;
