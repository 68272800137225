"use client"


import axios from "axios";

const url = "https://www.scrapmk.com/api";

/* 게시물 리스트 */
interface ApiResponse {
  count: number;
  next: string;
  previous: string;
  results: GocheolProduct[];
}

interface GocheolProduct {
  gocheol_id: number;
  bicheol_id: number;
  workcheol_id: number;
  pcb_id: number;
  plastic_id: number;
  
  wire_id: number;
  fabric_id: number;
  paper_id: number;
  specialmetal_id: number;
  machine_id: number;

  madae_id: number;
  tire_id: number;
  electronic_id: number;
  glass_id: number;
  demolition_id: number;

  truck_id: number;
  realestate_id: number;
  jobboard_id: number;
  trash_id: number;
  etc_id: number;

  profile_id: number;
  username: string;
  nickname: string;
  title: string;
  content: string;
  metro_city: string;
  shigungu: string;
  detail_location: string;
  lat: string;
  lng: string;
  buysell_sb: string;
  category2: string;
  board_hit: number;
  board_ing: string;
  image_1: string;
  image_2: string;
  image_3: string;
  image_4: string;
  image_5: string;
  image_6: string;
  image_7: string;
  image_8: string;
  price: number;
  weight_kg: number;
  madae_yn: string;
  sangchado_yn: string;
  like_count: number;
  delete_yn: string;
  created: string;
}


export const getNoticeDetail = async (category: string): Promise<ApiResponse> => {
  const result = await axios.get(`${url}/${category}/?&delete_yn=n`);
  return result.data; 
};

/* 게시물 상세 
  [ 고철 비철 작업철 PCB 플라스틱
    전선 헌옷 파지 특수금속 유리  ] */

interface CommonDetail {
  gocheol_id: number;
  bicheol_id: number;
  workcheol_id: number;
  pcb_id: number;
  plastic_id: number;

  wire_id: number;
  fabric_id: number;
  paper_id: number;
  specialmetal_id: number;

  glass_id: number;



  profile_id: number;
  username: string;
  nickname: string;
  title: string;
  content: string;
  metro_city: string;
  shigungu: string;
  detail_location: string;
  lat: string;
  lng: string;
  buysell_sb: string;
  category2: string;
  board_hit: number;
  board_ing: string;
  image_1: string;
  image_2: string;
  image_3: string;
  image_4: string;
  image_5: string;
  image_6: string;
  image_7: string;
  image_8: string;
  
  /* 공용 */ 
  price: number;
  weight_kg: number;
  madae_yn: string;
  sangchado_yn: string;
  like_count: number;
  delete_yn: string;
  created: string;

  /* 기계부품 */ 
  machine_price: number ,
  machine_name: string ,
  scrap_yn:  string ,

  /* 마대 */ 
  madae_category : string ,
  madae_size : number ,
  madae_total_cnt : number ,
  price_per_madae : number ,

  /* 철거 . 부동산*/ 
  land_size : string,
  
  /* 화물차 */ 
  truck_category : string ,
  truck_release_year : number , 
  truck_price: number ,
  truck_distance: number ,
  truck_ton: number ,
  truck_option : string ,

  /* 부동산 */ 
  realestate_category : string ,
  building_size : number ,
  realestate_price : number ,
  deposit: number,
  monthly_rent: number,

  /* 구인구직 */ 
  job_category : string ,
  job_pay : number ,
  job_nego : string ,
  job_requirement : string ,
  job_outsource : string ,

  /* 폐기물 */ 
  trash_amount : string ,
  trash_location : string , 
  trash_combustible_yn : string ,
  trash_transport_provided_yn : string ,

  /* 기타 */ 
  etc_name : string , 
  etc_price : number
}

export const CommonProductDetail = async (category: string , pk:string): Promise<CommonDetail> => {
  const result = await axios.get(`${url}/${category}/${pk}`);
  return result.data; 
};

export type {
  ApiResponse,
  CommonDetail  
}

