// Footer.js

import React from 'react';
import './globals.css';
import './page.css';
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";


function Footer() {

    const isMobile = useMediaQuery({ maxWidth: 1080 });
    const naviagte = useNavigate()

    return (
        <footer className="footer">
            <div className="footerInner">
                <div className="footerInnerFirst">
                    <p onClick={() => naviagte(`/Terms/Use/${"use"}`)}>이용약관</p>
                    <p>|</p>
                    <p onClick={() => naviagte(`/Terms/Use/${"user"}`)}>개인정보처리방침</p>
                    <p>|</p>
                    <p onClick={() => naviagte("/CustomerService/Inquire")}>고객센터</p>
                </div>
                <div className="FooterContentBox">
                    <img
                        width={120}
                        height={56}
                        src="https://s3.ap-northeast-2.amazonaws.com/scrapmarket-2.0.0/Icon/SMK-logo.svg"
                        alt="Logo"
                    />
                    <Link to='https://play.google.com/store/apps/details?id=com.scrapmarket.scrapmarket' className='FooterLocate'>
                        {isMobile ?
                            <img src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Common/PlayStore_logo.png" alt='playstoe' width={18} height={20} ></img>
                            :
                            <img src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Common/PlayStore_Logo_Text_Include.png" alt='playstoe' className='FooterPlayStore' ></img>
                        }
                        <p>앱 다운로드</p>
                        {isMobile ?
                            <img src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Common/Common_Playstore_Download.png" alt='Download' width={16} height={16} />
                            :
                            <img src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Common/Common_Playstore_Download.png" alt='Download' width={24} height={24} />
                        }
                    </Link>
                </div>

                <div className='FooterBusinessname'>
                    주식회사 어글리스톤
                </div>

                <div className='AddressBox'>
                    <p>서울특별시 강남구 삼성로 508 1804호 (LG트윈텔2차, 삼성동)</p>
                    {isMobile ? "" : <>
                        <p>|</p>
                        <p>사업자등록번호 : 449-87-02373</p>
                        <p>|</p>
                        <p>통신판매업신고 : 2022-서울강남-04783호</p>
                    </>
                    }
                </div>
                    {isMobile ?  
                        <div className='AddressBox'>
                            <p>사업자등록번호 : 449-87-02373</p>
                            <p>|</p>
                            <p>통신판매업신고 : 2022-서울강남-04783호</p>
                        </div> : ""
                    }
                <div className='AddressBox'>
                    <p>대표이사 : 최우섭</p>
                </div>

                <div className='FooterTelBox'>
                    <p>Tel : 02-2038-2123</p>
                    <p>|</p>
                    <p>Mail : cs@ugst.co.kr</p>
                    <p>|</p>
                    <p>Fax : 02-6918-0965</p>
                </div>

                <div className='AddressBox'>
                    <p>개인정보관리책임자 : 최우섭</p>
                </div>

                <div className='AddressBox'>
                    <p>Copyright Ugly Stone Co.,Ltd. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
