"use client"

import "./page.css";
import { FAQJSON } from "./FAQJSON";
import { useState } from "react";
import Dropdown from './Dropdown';
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";

function Question() {
  const [faqData, setFaqData] = useState(FAQJSON);
  const [active, setActive] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("전체");

  const toggleActive = (id:number) => {
    setFaqData(faqData.map((item) =>
      item.id === id ? { ...item, active: !item.active } : item
    ));
  };

  const handleSelectOption = (option: string) => {
    if (option === '전체') {
      setFilter(option);
    } else {
      setFilter(option);
    }
  };

  return (
    <>
    <Header/>
    <div className="QuestionBox">
      
      {/* 상단 */}
      <div className="QuestionTitle">
        <div className="QuestionTitleInner">
          <p className="QuestionTitleFirst">자주하는 질문</p>
          <p className="QuestionTitleSecond">회원님들께서 가장 자주하시는 질문들을 모았습니다.</p>
          <div className="QuestionTitleThird" onClick={() => active ? setActive(false) : setActive(true) }>
          <Dropdown onSelectOption={handleSelectOption}/>
          </div>
        </div>
      </div>

      <div className="QuestionContentTitle">
        <div className="QuestionContentInner">
          <p className="QuestionContentFirst">구분</p>
          <p className="QuestionContentSecond">제목</p>
          <p></p>
        </div>
      </div>

      {faqData.filter(filter === "전체" ? () => true : (word) => word.category === filter).map((a) => {
        return (
          <div key={a.id} className="QuestionListbox">
              <div className="QuestionListInner" onClick={() => toggleActive(a.id)}>
                <div className="QuestionListTitle">
                  <p className="QuestionListCategory">{a.category}</p>
                  <p  className="QuestionListTitleSecond">{a.question}</p>
                  <img src={a.active ? "https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Customer/FAQ_dropdown.svg" 
                  : "https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Customer/FAQ_dropdown_active.svg"} alt="dropdown icon" width={16} height={16} />
                </div>

                {a.active && (
                  <div className="QuestionListContent">
                    <p>{a.answer}</p>
                  </div>
                )}
              </div>
          </div>
        )
      })}
    </div>
    <Footer/>
    </>
  );
}

export default Question;
