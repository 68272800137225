"use client"

import { useState } from "react";
import "./page.css"

export default function ImageCount({ data , sendDataToParent , imagenumber , imagechange , modal_image }:
     { data: string | undefined , sendDataToParent:any , imagenumber : number , imagechange:boolean , modal_image:boolean}  ) {

    const [imagecount, setImageCount] = useState<number>(0);

    
    
    function handleClick(i:number) {
        setImageCount(i)
        sendDataToParent(i);

    }

    let renderDots = (count: number) => {
        let dots = [];
        if(modal_image){
            for (let i = 0; i < count; i++) {
                {i === imagenumber ?
                    dots.push(<div key={i} className = "dotsStyleModal"></div>)
                    :
                    dots.push(<div key={i} className = "emptyDotsStyleModal"  onClick={() => handleClick(i)}></div>)
                }
            }
            return dots;
        }
        else{
            if(imagechange){
                for (let i = 0; i < count; i++) {
                    {i === imagenumber ?
                        dots.push(<div key={i} className = "dotsStyle"></div>)
                        :
                        dots.push(<div key={i} className = "emptyDotsStyle"  onClick={() => handleClick(i)}></div>)
                    }
                }
                return dots;
            }
            else{
                for (let i = 0; i < count; i++) {
                    {i === imagecount ?
                        dots.push(<div key={i} className = "dotsStyle"></div>)
                        :
                        dots.push(<div key={i} className = "emptyDotsStyle" onClick={() => handleClick(i)}></div>)
                    }
                }
                return dots;
            }
        }
        
    }

    return (
        <>
            {data === "1개" ?
                <div className="dotsBox">
                    {renderDots(1)}
                </div> :
                data === "2개" ?
                    <div className="dotsBox">
                        {renderDots(2)}
                    </div> :
                    data === "3개" ?
                        <div className="dotsBox">
                            {renderDots(3)}
                        </div> :
                        data === "4개" ?
                            <div className="dotsBox">
                                {renderDots(4)}
                            </div> :
                            data === "5개" ?
                                <div className="dotsBox">
                                    {renderDots(5)}
                                </div> :
                                data === "6개" ?
                                    <div className="dotsBox">
                                        {renderDots(6)}
                                    </div> :
                                    data === "7개" ?
                                        <div className="dotsBox">
                                            {renderDots(7)}
                                        </div> :
                                        data === "8개" ?
                                            <div className="dotsBox">
                                                {renderDots(8)}
                                            </div>
                                            : null


            }
        </>
    )
}
