"use client"

import { NoticetData } from "../../../../api/Customer"
import { NoticeDetail } from "../../../../api/Customer";
import { useEffect, useState } from "react";
import "./page.css";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Header from "../../../../Common/Header";
import Footer from "../../../../Common/Footer";

type PageParams = {
    slug: string
}

function CustomerServiceFirstDetail({ params }: { params: string }) {

    const [notice, setNotice] = useState<NoticetData>();
    const { id } = useParams<{ id: string }>();

    /* 날짜 변환 */
    const formatDate = (dateString: any) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const formattedDate = new Intl.DateTimeFormat("ko-KR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit"
        }).format(date);
        return formattedDate.replace(/\./g, ". ");
    };

    /* API 호출 */
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await NoticeDetail(String(id));
                setNotice(response);
                console.log(response)
            } catch (error) {
            }
        }
        fetchData()
    }, []);
    return (
        <>
            <Header />
            <div className="NoticeBox">

                {/* 상단이미지 */}
                <div className="NoticeDetailImgBox">
                    {/* <img src ="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Customer/Notice_image.png"/> */}
                </div>

                {/* 공지사항 상세*/}
                <div className="NoticeTop">
                    <div className="NoticeDetailTopInner">
                        <p className="NoticeTopInnertitle">공지사항</p>
                        <p className="NoticeTopInnercontent">스크랩마켓의 새로운 소식들과 정보들을 확인하세요.</p>
                    </div>
                </div>
                <div className="NoticeTopSecondbox">
                    <div className="NoticeDetailTopSecond">
                        <p className="NoticeTopSecondLeft" style={{ fontWeight: "700", textAlign: "left" }}>제목</p>
                        <p className="NoticeTopSecondCenter" style={{ textAlign: "left" }}>{notice?.title}</p>
                        <p className="NoticeTopSecondRight"></p>
                    </div>
                </div>

                <div className="NoticeTopSecondbox">
                    <div className="NoticeDetailTopSecond">
                        <p className="NoticeTopSecondLeft" style={{ fontWeight: "700", textAlign: "left" }}>작성일</p>
                        <p className="NoticeTopSecondCenter" style={{ textAlign: "left" }}>{formatDate(notice?.created).slice(0, -2)}</p>
                        <p className="NoticeTopSecondRight"></p>
                    </div>
                </div>

                <div className="NoticeContentbox">
                    <p className="NoticeTopSecondLeft" style={{ fontWeight: "700", textAlign: "left" }}>내용</p>
                    <div className="NoticeDetailTopSecond">
                        <p className="NoticeTopSecondCenter" style={{ textAlign: "left", whiteSpace: "pre-wrap" }}>{notice?.content}</p>
                        <p className="NoticeTopSecondRight"></p>
                    </div>
                </div>

                <div className="NoticeFilebox">
                    <div className="NoticeDetailTopSecond">
                        <p className="NoticeTopSecondLeftFile" style={{ fontWeight: "700", textAlign: "left" }}>첨부파일</p>
                        <p className="NoticeTopSecondFileCenter" style={{ textAlign: "right", justifyContent: "flex-end" }}>{notice?.file === null ? "-" : String(notice?.file)}</p>
                        <p className="NoticeTopSecondRight"></p>
                    </div>
                </div>

                {/* 목록으로 이동 */}
                <div className="LocateNoticeBox">
                    <Link to={`/CustomerService/Notice`} className="Locate">
                        목록으로
                    </Link>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default CustomerServiceFirstDetail


