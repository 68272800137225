"use client"

import ScrapMetal from "../../ScrapMetal/[slug]/page"

type PageParams = {
  slug: string;
}

export default function Plastic({ params  }: { params: string} ) {


  return (
    <>
     <ScrapMetal params = {params} category="플라스틱" api_category = "plastic" />
    </>
  )
}