"use client"

import "../../Common/page.css";
import BusinessCard from "../../Businesscard/BusinessCard";
import { CommonDetail } from "../.././../../api/Product";
import { CommonProductDetail } from "../.././../../api/Product";
import { useState, useEffect } from "react";
import NaverMap from "../../Common/NaverMap";
import ImageCount from "../../Common/ImageCount";
import { useNavigate, useParams, Link } from "react-router-dom";
import Header from "../../../../Common/Header";
import Footer from "../../../../Common/Footer";
import { useMediaQuery } from "react-responsive";


interface ScrapMetalProps {
  params: string;
  category: string;
  api_category: string;
}


/* 닉네임 마스킹 */
function maskNickname(nickname: string | undefined, maskChar = '*', unmaskedLength = 3) {
  if (!nickname) return '';
  const unmaskedPart = nickname.slice(0, unmaskedLength);
  const maskedPart = maskChar.repeat(nickname.length - unmaskedLength);
  return unmaskedPart + maskedPart;
}

/* 날짜 변환 */
function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줍니다.
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}:${month}:${day}`;
}


export default function ScrapMetal({ params, category, api_category }: { params: string, category: string, api_category: string }) {

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [products, setProducts] = useState<CommonDetail>();
  const [image_number, setImage_number] = useState<number>(0);
  const [image_list, setImage_list] = useState<any>([]);

  const [maximage, setMaximage] = useState<number>(0);
  const [image_change, setImage_change] = useState<boolean>(false);
  const [modal_image, setModal_image] = useState<boolean>(false);
  const [image, setImage] = useState<string | undefined>("");

  /* 모바일 슬라이드 */
  const [tochedX, setTochedX] = useState<number | undefined>(0);
  const [tochedY, setTochedY] = useState<number | undefined>(0);
  const [modal_index, setModal_index] = useState<number>(0);

  /* PC 슬라이드 */
  const [mouseDownClientX, setMouseDownClientX] = useState<number>(0);
  const [mouseDownClientY, setMouseDownClientY] = useState<number>(0);
  const [mouseUpClientX, setMouseUpClientX] = useState<number>(0);
  const [mouseUpClientY, setMouseUpClientY] = useState<number>(0)
  const isMobile = useMediaQuery({ maxWidth: 1080 });



  /* API 호출 */
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CommonProductDetail(api_category === "" ? "gocheol" : api_category, String(id));
        setProducts(response);
        setImage_list(
          [
            response?.image_1, response?.image_2 === "" ? undefined : response?.image_2,
            response?.image_3 === "" ? undefined : response?.image_3, response?.image_4 === "" ? undefined : response?.image_4,
            response?.image_5 === "" ? undefined : response?.image_5, response?.image_6 === "" ? undefined : response?.image_6,
            response?.image_7 === "" ? undefined : response?.image_7, response?.image_8 === "" ? undefined : response?.image_8
          ]
        )

        if (response?.image_1 === "https://scrapmarket.s3.ap-northeast-2.amazonaws.com/img/KakaoTalk_20220628_170713075.jpg" || response?.image_2 == "") {
          setMaximage(1)
          setImage_list(
            [
              response?.image_1
            ]
          )
        }
        else if (response?.image_3 == "") {
          setMaximage(2)
          setImage_list(
            [
              response?.image_1, response?.image_2
            ]
          )
        }
        else if (response?.image_4 == "") {
          setMaximage(3)
          setImage_list(
            [
              response?.image_1, response?.image_2, response?.image_3
            ]
          )
        }
        else if (response?.image_5 == "") {
          setMaximage(4)
          setImage_list(
            [
              response?.image_1, response?.image_2, response?.image_3, response?.image_4
            ]
          )
        }
        else if (response?.image_6 == "") {
          setMaximage(5)
          setImage_list(
            [
              response?.image_1, response?.image_2, response?.image_3, response?.image_4,
              response?.image_5
            ]
          )
        }
        else if (response?.image_7 == "") {
          setMaximage(6)
          setImage_list(
            [
              response?.image_1, response?.image_2, response?.image_3, response?.image_4,
              response?.image_5, response?.image_6
            ]
          )
        }
        else if (response?.image_8 == "") {
          setMaximage(7)
          setImage_list(
            [
              response?.image_1, response?.image_2, response?.image_3, response?.image_4,
              response?.image_5, response?.image_6, response?.image_7
            ]
          )
        }
        else {
          setMaximage(8)
          setImage_list(
            [
              response?.image_1, response?.image_2, response?.image_3, response?.image_4,
              response?.image_5, response?.image_6, response?.image_7, response?.image_8
            ]
          )
        }
      } catch (error) {
      }
    }
    fetchData()
  }, []);

  useEffect(() => {
    const dragSpaceX = Math.abs(mouseDownClientX - mouseUpClientX);
    const dragSpaceY = Math.abs(mouseDownClientY - mouseUpClientY);
    const vector = dragSpaceX / dragSpaceY;

    if (mouseDownClientX !== 0 && dragSpaceX > 1 && vector > 1) {
      if (mouseUpClientX < mouseDownClientX) {
        if (image_list[maximage - 1] != image) {
          setModal_index(modal_index + 1)
          setImage(image_list[modal_index + 1])
          setImage_number(modal_index + 1)
        }
      }
      else if (mouseUpClientX > mouseDownClientX) {
        if (image_list[0] != image) {
          setModal_index(modal_index - 1)
          setImage(image_list[modal_index - 1])
          setImage_number(modal_index - 1)
        }
      }
    }
  }, [mouseUpClientX]);

  /* 닉네임 마스킹 */
  const maskedNickname = maskNickname(products?.username);

  /* 날짜 변환 */
  const formattedDate = formatDate(String(products?.created));

  /* 이미지 전환 dot click시 받는 정보 */
  function handleDataFromChild(imageIndex: number) {
    setImage_number(imageIndex)
    setModal_index(imageIndex)
    setImage(image_list[imageIndex])
  }

  /* 이미지 확대 모바일 */
  const onTouchStart = (e: React.TouchEvent) => {
    setTochedX(e.changedTouches[0].pageX);
    setTochedY(e.changedTouches[0].pageY);
  };

  /* 이미지 확대 모바일 */
  const onTouchEnd = (e: React.TouchEvent) => {
    if (tochedX === undefined || tochedY === undefined) return;

    const distanceX = tochedX - e.changedTouches[0].pageX;
    const distanceY = tochedY - e.changedTouches[0].pageY;
    const vector = Math.abs(distanceX / distanceY);

    if (distanceX > 30 && vector > 2) {
      if (image_list[maximage - 1] != image) {
        setModal_index(modal_index + 1)
        setImage(image_list[modal_index + 1])
        setImage_number(modal_index + 1)
      }
    }
    else if (distanceX < -30 && vector > 2) {
      if (image_list[0] != image) {
        setModal_index(modal_index - 1)
        setImage(image_list[modal_index - 1])
        setImage_number(modal_index - 1)
      }
    }
  };

  const onMouseDown = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setMouseDownClientX(e.clientX);
    setMouseDownClientY(e.clientY);
  };
  const onMouseUp = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setMouseUpClientX(e.clientX);
    setMouseUpClientY(e.clientY);
  };

  const handleImageChange = (direction: 'next' | 'back') => {
    if (direction === 'next') {
      if (image_number < maximage - 1) {
        setImage_number(image_number + 1);
        setImage_change(true);
      }
    } else {
      if (image_number > 0) {
        setImage_number(image_number - 1);
        setImage_change(true);
      }
    }
  };

  return (
    <>
      <Header />
      {modal_image ?
        <div className="modalbox">
          <img src={image} className="modalImg"
            onTouchEnd={onTouchEnd}
            onTouchStart={onTouchStart}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
          ></img>
          <div style={{ position: "absolute", top: "30px", right: "30px", color: "white", fontSize: "60px" }} onClick={() => setModal_image(false)}>X</div>
          <div className="ScrapMetaldot">
            {
              products?.image_1 === "https://scrapmarket.s3.ap-northeast-2.amazonaws.com/img/KakaoTalk_20220628_170713075.jpg" ?
                <ImageCount data={"1개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount> :
                products?.image_2 === "" ?
                  <ImageCount data={"1개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount> :
                  products?.image_3 === "" ?
                    <ImageCount data={"2개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount> :
                    products?.image_4 === "" ?
                      <ImageCount data={"3개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount> :
                      products?.image_5 === "" ?
                        <ImageCount data={"4개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount> :
                        products?.image_6 === "" ?
                          <ImageCount data={"5개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount> :
                          products?.image_7 === "" ?
                            <ImageCount data={"6개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount> :
                            products?.image_8 === "" ?
                              <ImageCount data={"7개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount> :
                              <ImageCount data={"8개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount>
            }
          </div>
        </div>
        :
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
          <div className="ScrapMetalBox">

            {/* 상단  */}
            <div className="ScrapMetalBack" onClick={() => navigate(-1)}>
              {isMobile ?
                <img
                  style={{ marginLeft: "0px" }}
                  src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Product/Detail_post.png"
                  width={24}
                  height={24}
                  alt="back"
                />
                :
                <img
                  style={{ marginLeft: "40px" }}
                  src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Product/Detail_post.png"
                  width={24}
                  height={24}
                  alt="back"
                />
              }
              <p>목록으로 돌아가기</p>
            </div>

            {/* 제목 */}
            <div className="ScrapMetalTitle">
              <p>{products?.title}</p>
            </div>

            {/* 이미지 */}
            <div className="ScrapMetalImgBox">
              <div className="ScrapMetalImgInnerBox">
                <img
                  className="ScrapMetalImgBack" onClick={() => handleImageChange('back')}
                  src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Product/Detail_Back.png"
                  alt="Back"
                />
                {image_number === 0 ?
                  <img src={products?.image_1} className="ScrapMetalMainImg" onClick={() => {
                    setModal_image(true)
                    setImage(products?.image_1)
                    setModal_index(0)
                  }}></img>
                  : image_number === 1 ?
                    <img src={products?.image_2} className="ScrapMetalMainImg" onClick={() => {
                      setModal_image(true)
                      setImage(products?.image_2)
                      setModal_index(1)
                    }}></img>
                    : image_number === 2 ?
                      <img src={products?.image_3} className="ScrapMetalMainImg" onClick={() => {
                        setModal_image(true)
                        setImage(products?.image_3)
                        setModal_index(2)
                      }}></img>
                      : image_number === 3 ?
                        <img src={products?.image_4} className="ScrapMetalMainImg" onClick={() => {
                          setModal_image(true)
                          setImage(products?.image_4)
                          setModal_index(3)
                        }}></img>
                        : image_number === 4 ?
                          <img src={products?.image_5} className="ScrapMetalMainImg" onClick={() => {
                            setModal_image(true)
                            setImage(products?.image_5)
                            setModal_index(4)
                          }}></img>
                          : image_number === 5 ?
                            <img src={products?.image_6} className="ScrapMetalMainImg" onClick={() => {
                              setModal_image(true)
                              setImage(products?.image_6)
                              setModal_index(5)
                            }}></img>
                            : image_number === 6 ?
                              <img src={products?.image_7} className="ScrapMetalMainImg" onClick={() => {
                                setModal_image(true)
                                setImage(products?.image_7)
                                setModal_index(6)
                              }}></img>
                              :
                              <img src={products?.image_8} className="ScrapMetalMainImg" onClick={() => {
                                setModal_image(true)
                                setImage(products?.image_8)
                                setModal_index(7)
                              }}></img>
                }
                <img
                  className="ScrapMetalImgNext" onClick={() => handleImageChange('next')}
                  src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Product/Detail_Next.png"
                  alt="Next"
                />
              </div>

              <div className="ScrapMetaldot">
                {
                  products?.image_1 === "https://scrapmarket.s3.ap-northeast-2.amazonaws.com/img/KakaoTalk_20220628_170713075.jpg" ?
                    <ImageCount data={"1개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount> :
                    products?.image_2 === "" ?
                      <ImageCount data={"1개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount> :
                      products?.image_3 === "" ?
                        <ImageCount data={"2개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount> :
                        products?.image_4 === "" ?
                          <ImageCount data={"3개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount> :
                          products?.image_5 === "" ?
                            <ImageCount data={"4개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount> :
                            products?.image_6 === "" ?
                              <ImageCount data={"5개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount> :
                              products?.image_7 === "" ?
                                <ImageCount data={"6개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount> :
                                products?.image_8 === "" ?
                                  <ImageCount data={"7개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount> :
                                  <ImageCount data={"8개"} sendDataToParent={handleDataFromChild} imagenumber={image_number} imagechange={image_change} modal_image={modal_image}></ImageCount>
                }
              </div>

            </div>

            {/* 닉네임 , 날짜 */}
            <div className="ScrapMetalNicknamebox">
              <div className="ScrapMetalNicknameInnerbox">
                <p className="ScrapMetalNickname">작성자 : {products?.nickname}{"("}{maskedNickname}{")"}</p>
                <p className="ScrapMetalToday">날짜 : {formattedDate}</p>
              </div>
            </div>

            {/* 정보 */}
            <div className="ScrapMetalInfobox">
              {isMobile ?
                <div style={{
                  width: "100%", height: "42px", display: "flex", justifyContent: "center", alignItems: "center",
                  color: "rgba(19, 193, 87, 1)", fontWeight: "700", fontSize: "16px"
                }}>
                  상세정보
                </div>
                :
                <></>
              }
              <div className="ScrapMetalInfoInner">

                {/* 게시물 정보 */}
                <div className="ScrapMetalInfoFirst">
                  {/* 지역 */}
                  <div className="ScrapMetalList">
                    <p className="ScrapMetalListTitle">지역</p>
                    <p className="ScrapMetalListOption">{products?.metro_city}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{products?.shigungu}</p>
                  </div>

                  {/* 카테고리 */}
                  <div className="ScrapMetalList">
                    <p className="ScrapMetalListTitle">카테고리</p>
                    <p className="ScrapMetalListOption">{category === undefined ? "고철" : category}&nbsp;&nbsp;&nbsp;&nbsp;{"<"}&nbsp;&nbsp;&nbsp;&nbsp;{products?.category2}</p>
                  </div>

                  {/* 상세주소 */}
                  <div className="ScrapMetalList">
                    <p className="ScrapMetalListTitle">상세주소</p>
                    {products?.detail_location === "" || products?.detail_location === "위치가 지도에 표시됩니다" ?
                      <p className="ScrapMetalListOption">-</p>
                      :
                      <p className="ScrapMetalListOption">{products?.detail_location}</p>
                    }
                  </div>

                  {category === "기계부품" ?
                    <>
                      {/* 물품명 */}
                      <div className="ScrapMetalList">
                        <p className="ScrapMetalListTitle">물품명</p>
                        {products?.machine_name === "" || products?.machine_name === undefined ?
                          <p className="ScrapMetalListOption">-</p>
                          :
                          <p className="ScrapMetalListOption">{products?.machine_name}</p>
                        }
                      </div>

                      {/* 가격 */}
                      <div className="ScrapMetalList">
                        <p className="ScrapMetalListTitle">가격</p>
                        {products?.machine_price === 0 || products?.machine_price === undefined ?
                          <p className="ScrapMetalListOption">-</p>
                          :
                          <p className="ScrapMetalListOption">{products?.machine_price}원</p>
                        }
                      </div>

                      {/* 중고/스크랩 */}
                      <div className="ScrapMetalList" style={{ borderBottom: isMobile ? "" : "none" }}>
                        <p className="ScrapMetalListTitle">중고/스크랩</p>
                        {products?.scrap_yn === "Y" ?
                          <p className="ScrapMetalListOption">중고</p>
                          :
                          <p className="ScrapMetalListOption">스크랩</p>
                        }
                      </div>
                    </>
                    : category === "마대" ?
                      <>
                        {/* 마대중량 */}
                        <div className="ScrapMetalList">
                          <p className="ScrapMetalListTitle">마대중량</p>
                          {products?.madae_category === "A" && products?.madae_size === 0 ?
                            <p className="ScrapMetalListOption">-</p>
                            :
                            products?.madae_category === "A" && products?.madae_size != 0 ?
                              <p className="ScrapMetalListOption">{products?.madae_size}kg</p>
                              :
                              products?.madae_category === "B" ?
                                <p className="ScrapMetalListOption">1톤 초과마대</p>
                                :
                                <p className="ScrapMetalListOption">특수마대</p>
                          }
                        </div>

                        {/* 총수량 */}
                        <div className="ScrapMetalList">
                          <p className="ScrapMetalListTitle">총수량</p>
                          {products?.madae_total_cnt === 0 ?
                            <p className="ScrapMetalListOption">-</p>
                            :
                            <p className="ScrapMetalListOption">{products?.madae_total_cnt}장</p>
                          }
                        </div>

                        {/* 장당단가 */}
                        <div className="ScrapMetalList" style={{ borderBottom: isMobile ? "" : "none" }}>
                          <p className="ScrapMetalListTitle">장당단가</p>
                          {products?.price_per_madae === 0 ?
                            <p className="ScrapMetalListOption">-</p>
                            :
                            <p className="ScrapMetalListOption">{products?.price_per_madae}원</p>
                          }
                        </div>
                      </>
                      : category === "철거" ?
                        <>
                          {/* 면적 */}
                          <div className="ScrapMetalList" style={{ borderBottom: isMobile ? "" : "none" }}>
                            <p className="ScrapMetalListTitle">면적</p>
                            {products?.land_size === "0.0" ?
                              <p className="ScrapMetalListOption">-</p>
                              :
                              <p className="ScrapMetalListOption">{products?.land_size}평</p>
                            }
                          </div>
                        </>
                        : category === "화물차" ?
                          <>
                            {/* 차종 */}
                            <div className="ScrapMetalList">
                              <p className="ScrapMetalListTitle">차종</p>
                              {products?.truck_category === "0" ?
                                <p className="ScrapMetalListOption">-</p>
                                :
                                <p className="ScrapMetalListOption">{products?.truck_category}</p>
                              }
                            </div>

                            {/* 희망가격 */}
                            <div className="ScrapMetalList">
                              <p className="ScrapMetalListTitle">희망가격</p>
                              {products?.truck_price === 0 ?
                                <p className="ScrapMetalListOption">-</p>
                                :
                                <p className="ScrapMetalListOption">{products?.truck_price}만원</p>
                              }
                            </div>

                            {/* 연식 */}
                            <div className="ScrapMetalList">
                              <p className="ScrapMetalListTitle">연식</p>
                              {products?.truck_release_year === 0 ?
                                <p className="ScrapMetalListOption">-</p>
                                :
                                <p className="ScrapMetalListOption">{products?.truck_release_year}년</p>
                              }
                            </div>

                            {/* 주행거리 */}
                            <div className="ScrapMetalList">
                              <p className="ScrapMetalListTitle">주행거리</p>
                              {products?.truck_distance === 0 ?
                                <p className="ScrapMetalListOption">-</p>
                                :
                                <p className="ScrapMetalListOption">{products?.truck_distance}만km</p>
                              }
                            </div>

                            {/* 톤수 */}
                            <div className="ScrapMetalList">
                              <p className="ScrapMetalListTitle">톤수</p>
                              {products?.truck_ton === 0 ?
                                <p className="ScrapMetalListOption">-</p>
                                :
                                <p className="ScrapMetalListOption">{products?.truck_ton}t</p>
                              }
                            </div>

                            {/* 기타옵션 */}
                            <div className="ScrapMetalList" style={{ borderBottom: isMobile ? "" : "none" }}>
                              <p className="ScrapMetalListTitle">기타옵션</p>
                              {products?.truck_option === "0" ?
                                <p className="ScrapMetalListOption">-</p>
                                :
                                <p className="ScrapMetalListOption">{products?.truck_option}</p>
                              }
                            </div>
                          </>
                          : category === "부동산" ?
                            <>
                              {/* 매물종류 */}
                              <div className="ScrapMetalList">
                                <p className="ScrapMetalListTitle">매물종류</p>
                                {products?.realestate_category === "0" ?
                                  <p className="ScrapMetalListOption">-</p>
                                  :
                                  <p className="ScrapMetalListOption">{products?.realestate_category}</p>
                                }
                              </div>

                              {/* 대지평수 */}
                              <div className="ScrapMetalList">
                                <p className="ScrapMetalListTitle">대지평수</p>
                                {String(products?.land_size) === "0" ?
                                  <p className="ScrapMetalListOption">-</p>
                                  :
                                  <p className="ScrapMetalListOption">{products?.land_size}평</p>
                                }
                              </div>

                              {/* 건물평수 */}
                              <div className="ScrapMetalList">
                                <p className="ScrapMetalListTitle">건물평수</p>
                                {products?.building_size === 0 ?
                                  <p className="ScrapMetalListOption">-</p>
                                  :
                                  <p className="ScrapMetalListOption">{products?.building_size}평</p>
                                }
                              </div>

                              {/* 매매금액 */}
                              <div className="ScrapMetalList">
                                <p className="ScrapMetalListTitle">매매금액</p>
                                {products?.realestate_price === 0 ?
                                  <p className="ScrapMetalListOption">-</p>
                                  :
                                  <p className="ScrapMetalListOption">{products?.realestate_price}만원</p>
                                }
                              </div>

                              {/* 보증금 */}
                              <div className="ScrapMetalList">
                                <p className="ScrapMetalListTitle">보증금</p>
                                {products?.deposit === 0 ?
                                  <p className="ScrapMetalListOption">-</p>
                                  :
                                  <p className="ScrapMetalListOption">{products?.deposit}만원</p>
                                }
                              </div>

                              {/* 월세 */}
                              <div className="ScrapMetalList" style={{ borderBottom: isMobile ? "" : "none" }}>
                                <p className="ScrapMetalListTitle">월세</p>
                                {products?.monthly_rent === 0 ?
                                  <p className="ScrapMetalListOption">-</p>
                                  :
                                  <p className="ScrapMetalListOption">{products?.monthly_rent}만원</p>
                                }
                              </div>
                            </>
                            : category === "구인구직" ?
                              <>
                                {/* 구인분야 */}
                                <div className="ScrapMetalList">
                                  <p className="ScrapMetalListTitle">구인분야</p>
                                  {products?.job_category === "0" ?
                                    <p className="ScrapMetalListOption">-</p>
                                    :
                                    <p className="ScrapMetalListOption">{products?.job_category}</p>
                                  }
                                </div>

                                {/* 급여 */}
                                <div className="ScrapMetalList">
                                  <p className="ScrapMetalListTitle">급여</p>
                                  {products?.job_pay === 0 && products?.job_nego === "Y" ?
                                    <p className="ScrapMetalListOption">-&nbsp;&nbsp;&nbsp;&nbsp;협의가능</p>
                                    : products?.job_pay === 0 && products?.job_nego === "N" ?
                                      <p className="ScrapMetalListOption">-&nbsp;&nbsp;&nbsp;&nbsp;협의불가능</p>
                                      : products?.job_pay != 0 && products?.job_nego === "Y" ?
                                        <p className="ScrapMetalListOption">{products?.job_pay}만원&nbsp;&nbsp;&nbsp;&nbsp;협의가능</p>
                                        : <p className="ScrapMetalListOption">{products?.job_pay}만원&nbsp;&nbsp;&nbsp;&nbsp;협의불가능</p>
                                  }
                                </div>

                                {/* 필요사항 */}
                                <div className="ScrapMetalList">
                                  <p className="ScrapMetalListTitle">필요사항</p>
                                  {products?.job_requirement === "0" || products?.job_requirement === "" ?
                                    <p className="ScrapMetalListOption">-</p>
                                    :
                                    <p className="ScrapMetalListOption">{products?.job_requirement}</p>
                                  }
                                </div>


                                {/* 형태 */}
                                <div className="ScrapMetalList" style={{ borderBottom: isMobile ? "" : "none" }}>
                                  <p className="ScrapMetalListTitle">형태</p>
                                  {products?.job_outsource === "" || products?.job_outsource === "0" ?
                                    <p className="ScrapMetalListOption">-</p>
                                    :
                                    <p className="ScrapMetalListOption">{products?.job_outsource}</p>
                                  }
                                </div>
                              </>
                              : category === "폐기물" ?
                                <>
                                  {/* 중량 */}
                                  <div className="ScrapMetalList">
                                    <p className="ScrapMetalListTitle">중량</p>
                                    {products?.trash_amount === "A" ?
                                      <p className="ScrapMetalListOption">소량</p>
                                      : products?.trash_amount === "B" ?
                                        <p className="ScrapMetalListOption">반차(1톤기준)</p>
                                        : products?.trash_amount === "C" ?
                                          <p className="ScrapMetalListOption">한차(1톤기준)</p>
                                          : products?.trash_amount === "D" ?
                                            <p className="ScrapMetalListOption">1톤~2.5톤</p>
                                            : <p className="ScrapMetalListOption">2.5톤~5톤</p>
                                    }
                                  </div>

                                  {/* 장소 */}
                                  <div className="ScrapMetalList">
                                    <p className="ScrapMetalListTitle">장소</p>
                                    {products?.trash_location === "A" ?
                                      <p className="ScrapMetalListOption">건물 외부</p>
                                      : products?.trash_location === "B" ?
                                        <p className="ScrapMetalListOption">1층 내부</p>
                                        : products?.trash_location === "C" ?
                                          <p className="ScrapMetalListOption">2층 이상(엘리베이터O)</p>
                                          : <p className="ScrapMetalListOption">2층 이상(엘리베이터X)</p>
                                    }
                                  </div>

                                  {/* 가연성폐기물 */}
                                  <div className="ScrapMetalList">
                                    <p className="ScrapMetalListTitle">가연성폐기물</p>
                                    {products?.trash_combustible_yn === "Y" ?
                                      <p className="ScrapMetalListOption">가연성포함</p>
                                      :
                                      <p className="ScrapMetalListOption">가연성미포함</p>
                                    }
                                  </div>

                                  {/* 운반 */}
                                  <div className="ScrapMetalList" style={{ borderBottom: isMobile ? "" : "none" }}>
                                    <p className="ScrapMetalListTitle">운반</p>
                                    {products?.sangchado_yn === "Y" ?
                                      <p className="ScrapMetalListOption">상차인력제공</p>
                                      :
                                      <p className="ScrapMetalListOption">상차인력미제공</p>
                                    }
                                  </div>
                                </>
                                : category === "기타" ?
                                  <>
                                    {/* 물품명 */}
                                    <div className="ScrapMetalList">
                                      <p className="ScrapMetalListTitle">물품명</p>
                                      {products?.etc_name === "" || products?.etc_name === "0" ?
                                        <p className="ScrapMetalListOption">-</p>
                                        :
                                        <p className="ScrapMetalListOption">{products?.etc_name}</p>
                                      }
                                    </div>

                                    {/* 희망가격 */}
                                    <div className="ScrapMetalList" style={{ borderBottom: isMobile ? "" : "none" }}>
                                      <p className="ScrapMetalListTitle">희망가격</p>
                                      {products?.etc_price === 0 ?
                                        <p className="ScrapMetalListOption">-</p>
                                        :
                                        <p className="ScrapMetalListOption">{products?.etc_price}원</p>
                                      }
                                    </div>
                                  </>
                                  :
                                  <>
                                    {/* 중량 */}
                                    < div className="ScrapMetalList">
                                      <p className="ScrapMetalListTitle">중량</p>
                                      {products?.weight_kg === 0 ?
                                        <p className="ScrapMetalListOption">-</p>
                                        :
                                        <p className="ScrapMetalListOption">{Number(products?.weight_kg) / 1000}t(kg)</p>
                                      }
                                    </div>

                                    {/* 단가 */}
                                    <div className="ScrapMetalList">
                                      <p className="ScrapMetalListTitle">단가</p>
                                      {products?.price === 0 ?
                                        <p className="ScrapMetalListOption">-</p>
                                        :
                                        <p className="ScrapMetalListOption">{products?.price}kg당 원</p>
                                      }
                                    </div>

                                    {/* 마대작업 */}
                                    <div className="ScrapMetalList">
                                      <p className="ScrapMetalListTitle">마대작업</p>
                                      {products?.madae_yn === "Y" ?
                                        <p className="ScrapMetalListOption">완료</p>
                                        :
                                        <p className="ScrapMetalListOption">미완료</p>
                                      }
                                    </div>

                                    {/* 운반 */}
                                    <div className="ScrapMetalList" style={{ borderBottom: category === "타이어" || category === "전자제품" ? "" : isMobile ? "" : "none" }}>
                                      <p className="ScrapMetalListTitle">운반</p>
                                      {products?.sangchado_yn === "Y" ?
                                        <p className="ScrapMetalListOption">상차도</p>
                                        :
                                        products?.sangchado_yn === "N" ?
                                          <p className="ScrapMetalListOption">하차도</p>
                                          :
                                          <p className="ScrapMetalListOption">협의</p>
                                      }
                                    </div>

                                    {/* 중고/스크랩 */}
                                    {category === "타이어" || category === "전자제품" ?
                                      <div className="ScrapMetalList" style={{ borderBottom: isMobile ? "" : "none" }}>
                                        <p className="ScrapMetalListTitle">중고/스크랩</p>
                                        {products?.scrap_yn === "Y" ?
                                          <p className="ScrapMetalListOption">중고</p>
                                          :
                                          <p className="ScrapMetalListOption">스크랩</p>
                                        }
                                      </div> : <></>
                                    }
                                  </>
                  }


                </div>

                {/* 게시물 작성자 명함 */}
                <div className="DetailContent">
                  <p className="DetailContentTitle">상세내용</p>
                  <p className="DetailContentContent">{products?.content}</p>
                </div>
              </div>

            </div>

          </div>

          {/* 광고 */}
          <div className="AdBox">
            <div className="AdInner">
              {isMobile ?
                <div style={{ display:"flex" , flexDirection:"column" }}>
                  <p style={{ marginBottom:"10px"}}>광고 문의</p>
                  <p>cs@ugst.co.kr</p>
                </div>                  
              :
              "광고 문의 : cs@ugst.co.kr"
            }
            </div>
          </div>

          {/* 명함 . 지도 */}
          <div className="DetailContentBox">
            <div className="DetailMap">
              <NaverMap metro={products?.metro_city} shigungu={products?.shigungu} lat={products?.lat} lng={products?.lng} />
            </div>
            <BusinessCard data={products?.profile_id} />
          </div>

          {isMobile ?
            <div style={{ width: "100%", height: "170px", display: "flex", justifyContent: "center", alignItems: "center" }} >
              <Link to='https://play.google.com/store/apps/details?id=com.scrapmarket.scrapmarket'
                style={{ width: "330px", height: "70px", background: "rgba(19, 193, 87, 1)", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center", textDecoration: "none" }}>
                <img src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Common/PlayStore_Logo_Text_Include.png" width="171" height="40" alt="Locate" />
                <p style={{ marginLeft: "20px", fontSize: "20px", fontWeight: "700", color: "white", }}>앱다운로드</p>
              </Link>
            </div>
            :
            <div></div>
          }


        </div>
      }
      <Footer />
    </>
  )
}