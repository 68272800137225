"use client"


import axios from "axios";

const url = "https://www.scrapmk.com/api";

/* 공지사항 */


interface NoticeResponse {
    data: NoticetData[];
    page: NoticetPage;
}

interface NoticetData {
    id: number;
    title: string;
    content: string;
    file: null | File; // File type not fully defined in the provided data
    created: string; // ISO 8601 date string
}

interface NoticetPage {
    count: number;
    next: null | string; // URL for the next page (if available)
    previous: null | string; // URL for the previous page (if available)
}

export const getNoticeList = async (): Promise<NoticeResponse> => {
    const result = await axios.get(`${url}/base/noticelist/`);
    return result.data;
};

export const NoticeDetail = async (pk: string): Promise<NoticetData> => {
    const result = await axios.get(`${url}/base/notice/${pk}/`);
    return result.data; 
  };




export type {
    NoticeResponse,
    NoticetData
}