"use client"

import "./page.css";
import { useEffect, useState } from "react";
import { getNoticeList } from "../../../api/Customer";
import { NoticeResponse } from "../../../api/Customer";
import { useNavigate } from "react-router-dom";
import Header from "../../../Common/Header";
import Footer from "../../../Common/Footer";

function CustomerServiceFirst() {

  const navigate = useNavigate();
  const [list, setList] = useState<NoticeResponse>();

  /* 날짜 변환 */
  const formatDate = (dateString:any) => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat("ko-KR", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }).format(date);
    return formattedDate.replace(/\./g, ". ");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getNoticeList();
        console.log(response?.data[0].title)
        setList(response);
      } catch (error) {
      }
    };

    fetchData()
  }
    , []);


  return (
    <>
    <Header/>
    <div className="NoticeBox">

      {/* 상단이미지 */}
      <div className="NoticeImgBox">
      </div>

      {/* 공지사항 */}
      <div className="NoticeTop">
        <div className="NoticeTopInner">
          <p className="NoticeTopInnertitle">공지사항</p>
          <p className="NoticeTopInnercontent">스크랩마켓의 새로운 소식들과 정보들을 확인하세요.</p>
        </div>
      </div>
      <div className="NoticeTopSecondbox">
        <div className="NoticeTopSecond">
          <p className="NoticeTopSecondListLeft" style={{ fontWeight:"700" }}>번호</p>
          <p className="NoticeTopSecondMainCenter" style={{ textAlign:"center" , fontWeight:"700"}}>제목</p>
          <p className="NoticeTopSecondMainRight" style={{ fontWeight:"700" }}>작성일</p>
        </div>
      </div>

      {list?.data.map((a, index) => {
        return (
          <div key={index} className="NoticeListbox" onClick={() => 
            navigate(`/CustomerService/Notice/${a.id}`)
          }>
            <div className="NoticeTopSecond">
              <p className="NoticeTopSecondListLeft">{a.id}</p>
              <p className="NoticeTopSecondListCenter">{a.title}</p>
              <p className="NoticeTopSecondListRight">{formatDate(a.created).slice(0, -2)}</p>
            </div>
          </div>
        )
      })}
    </div>
    <Footer/>
    </>
  )
}

export default CustomerServiceFirst


