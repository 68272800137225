import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import App from './App';
import Post from './app/Product/page';

import ScrapMetal from './app/Product/ScrapMetal/[slug]/page';
import Nonferrous from './app/Product/Nonferrous/[slug]/page';
import Workcheol from './app/Product/WorkCheol/[slug]/page';
import PCB from './app/Product/PCB/[slug]/page';
import Plastic from './app/Product/Plastic/[slug]/page';

import Wire from './app/Product/Wire/[slug]/page';
import Cloth from './app/Product/Cloth/[slug]/page';
import Paper from './app/Product/Paper/[slug]/page';
import SpecialMetal from './app/Product/SpecialMetal/[slug]/page';
import Machine from './app/Product/Machine/[slug]/page';

import Madae from './app/Product/Madae/[slug]/page';
import Tire from './app/Product/Tire/[slug]/page';
import Electronic from './app/Product/Electronic/[slug]/page';
import Glass from './app/Product/Glass/[slug]/page';
import Demolition from './app/Product/Demolition/[slug]/page';

import Truck from './app/Product/Truck/[slug]/page';
import Realestate from './app/Product/Estate/[slug]/page';
import Jobboard from './app/Product/Jobboard/[slug]/page';
import Trash from './app/Product/Trash/[slug]/page';
import Etc from './app/Product/Etc/[slug]/page';

import CustomerServiceFirst from './app/CustomerService/Notice/page';
import CustomerServiceFirstDetail from './app/CustomerService/Notice/[slug]/page';
import Inquire from './app/CustomerService/Inquire/page';
import Question from './app/CustomerService/Question/page';

import TermsUse from './app/Terms/TermsUse';
import ScrollToTop from './Common/ScrollToTop';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ScrollToTop>
      <Routes>
          <Route path="/" element={<App />} />
          <Route path="/Product" element={<Post />}/>

          <Route path="/Product/ScrapMetal/:id" element={<ScrapMetal params="고철" category="고철" api_category="" />}/>
          <Route path="/Product/Nonferrous/:id" element={<Nonferrous params="exampleParam" />}/>
          <Route path="/Product/WorkCheol/:id" element={<Workcheol params="exampleParam" />}/>
          <Route path="/Product/PCB/:id" element={<PCB params="exampleParam" />}/>
          <Route path="/Product/Plastic/:id" element={<Plastic params="exampleParam" />}/>

          <Route path="/Product/Wire/:id" element={<Wire params="exampleParam" />}/>
          <Route path="/Product/Cloth/:id" element={<Cloth params="exampleParam" />}/>
          <Route path="/Product/Paper/:id" element={<Paper params="exampleParam" />}/>
          <Route path="/Product/SpecialMetal/:id" element={<SpecialMetal params="exampleParam" />}/>
          <Route path="/Product/Machine/:id" element={<Machine params="exampleParam" />}/>
          
          <Route path="/Product/Madae/:id" element={<Madae params="exampleParam" />}/>
          <Route path="/Product/Tire/:id" element={<Tire params="exampleParam" />}/>
          <Route path="/Product/Electronic/:id" element={<Electronic params="exampleParam" />}/>
          <Route path="/Product/Glass/:id" element={<Glass params="exampleParam" />}/>
          <Route path="/Product/Demolition/:id" element={<Demolition params="exampleParam" />}/>

          <Route path="/Product/Truck/:id" element={<Truck params="exampleParam" />}/>
          <Route path="/Product/Estate/:id" element={<Realestate params="exampleParam" />}/>
          <Route path="/Product/Jobboard/:id" element={<Jobboard params="exampleParam" />}/>
          <Route path="/Product/Trash/:id" element={<Trash params="exampleParam" />}/>
          <Route path="/Product/Etc/:id" element={<Etc params="exampleParam" />}/>

          <Route path="/CustomerService/Notice" element={<CustomerServiceFirst />}/>
          <Route path="/CustomerService/Notice/:id" element={<CustomerServiceFirstDetail params = ""/>}/>
          <Route path="/CustomerService/Question" element={<Question />}/>
          <Route path="/CustomerService/Inquire" element={<Inquire />}/>

          <Route path="/Terms/Use/:id" element={<TermsUse />}/>


      </Routes>
      </ScrollToTop>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
