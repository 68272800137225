"use client"

import ScrapMetal from "../../ScrapMetal/[slug]/page"

type PageParams = {
  slug: string;
}

export default function Cloth({ params  }: { params: string} ) {


  return (
    <>
     <ScrapMetal params = {params} category="헌옷" api_category = "fabric" />
    </>
  )
}