"use client"

import ScrapMetal from "../../ScrapMetal/[slug]/page"

type PageParams = {
  slug: string;
}

export default function Truck({ params  }: { params: string} ) {

  return (
    <>
     <ScrapMetal params = {params} category="화물차" api_category = "truck" />
    </>
  )
}