export const FAQJSON = [
  {
    id: 1,
    active : false ,
    category: "사이트 이용",
    question: "게시글 작성자와 대화를 하려면 어떻게 해야되나요?",
    answer:
      "게시글에 있는 [쪽지] 버튼을 클릭하시면, 새로운 쪽지를 작성하실 수 있습니다. 쪽지를 보내시면 대화를 하실 수 있습니다.",
  },
  {
    id: 2,
    active : false ,
    category: "사이트 이용",
    question: "고객센터 상담 방법이 궁금합니다.",
    answer:
      "고객센터 상담 방법은 3가지가 있습니다.\n1. 고객센터 - 문의하기 게시글 작성\n2. 이메일 문의\n3. 전화 문의\n가능한 빠른 답변 드리도록 노력하겠습니다.",
  },
  {
    id: 3,
    active : false ,
    category: "사이트 이용",
    question: "이용 등에 대한 문의는 어떻게 하나요?",
    answer:
      "화면 하단의 [고객 센터]에 들어가시면, [문의하기] 게시판이 있습니다. 문의하실 내용을 써주시면 최대한 빠르게 답변드리겠습니다.",
  },
  {
    id: 4,
    active : false ,
    category: "사이트 이용",
    question: "광고 또는 제휴 등의 문의는 어떻게 해야 되나요?",
    answer:
      "1. 1:1 문의, 이메일 등으로 문의주시면 됩니다.\n2. 메인 페이지 하단의 [광고문의] 부분을 클릭하시면 안내받으실 수 있습니다.",
  },
  {
    id: 5,
    active : false ,
    category: "사이트 이용",
    question: "명함은 어떻게 만드나요?",
    answer:
      "[마이페이지 - 명함관리]에서 명함에 들어갈 내용을 설정하실 수 있습니다.\n명함에 들어가는 회원정보를 수정하시려면, [마이페이지 - 회원정보관리]에서 회원정보를 수정해주시면 됩니다.",
  },
  {
    id: 6,
    active : false ,
    category: "사이트 이용",
    question: "'전체 지도'는 어떤 기능을 하나요?",
    answer:
      "[전체 지도]는 지도를 보면서 지역별 매물을 검색할 수 있는 기능입니다.\n지도 화면에서 넓게 볼 때와 확대해서 볼 때 보이는 종류에는 차이가 있습니다.\n넓게(축소) 볼 때 : 시군구별 매물 숫자가 표시, 클릭 시 해당 시군구의 매물이 하단에 표시됩니다. 물론 필터 기능도 사용하실 수 있습니다.\n좁게(확대) 볼 때 : 상세주소를 입력한 매물들이 해당 위치에 표시됩니다. 클릭 시 해당 매물이 하단에 표시됩니다. 해당 위치를 우측 상단의 [거리뷰] 버튼으로 살펴보는 것도 가능합니다.",
  },
  {
    id: 7,
    active : false ,
    category: "사이트 이용",
    question: "게시글을 볼 때, 필터 이용 방법을 알고 싶어요.",
    answer:
      "스크랩마켓에는 여러가지 필터가 있습니다.\n1. 판매 / 구매 필터 - 판매 매물, 구매 매물을 분류합니다.\n2. 카테고리 필터 - 카테고리 대분류의 하위 카테고리를 분류합니다.\n3. 제목/내용 검색 - 제목과 내용에 포함된 키워드로 검색합니다.\n4. 지역 검색 - 지역 별로 매물들을 검색합니다.\n여기까지는 공통 필터입니다.\n\n그 외 카테고리별로 매물들의 세부사항을 검색할 수 있는 필터가 있습니다.\n\n필터에 적용된 사항들은 중앙 부분에 표시되며, X표시를 눌러서 필터를 해제시킬 수 있습니다.",
  },
  {
    id: 8,
    active : false ,
    category: "사이트 이용",
    question: "베스트 게시판에는 어떤 게시글이 올라오나요?",
    answer:
      "전체 게시글 중 찜하기가 많은 게시글, 조회수가 많은 게시글과 추천매물들이 올라옵니다. 오른쪽 상단에서 선택을 하여 찜하기와 조회수, 추천매물 중에서 선택하실 수 있습니다.",
  },
  {
    id: 9,
    active : false ,
    category: "사이트 이용",
    question: "고객센터 전화 상담 이용 시간은 어떻게 되나요?",
    answer:
      "전화 상담 가능 시간은 10:00 ~ 17:00 입니다. 상담 가능한 회선이 모두 통화 중일 수 있으니 [고객센터 - 문의하기, 이메일 문의]를 이용해주시면 최대한 빠르게 답변드리겠습니다.",
  },
  {
    id: 10,
    active : false ,
    category: "사이트 이용",
    question: "문의 내용 답변은 어디에서 확인할 수 있나요?",
    answer:
      "문의 주신 방법으로 답변드립니다.\n- 문의하기 시 해당 문의글의 답글 \n- 메일 문의는 보낸 메일주소로 답변\n드리겠습니다.",
  },
  {
    id: 11,
    active : false ,
    category: "사이트 이용",
    question: "불량이용자 신고는 어떻게 할 수 있나요?",
    answer:
      "해당 게시글 상단의 [신고하기] 기능을 이용하시면 됩니다. 신고 버튼을 클릭하시면, 신고하기 페이지로 이동되며, 사유 및 내용을 보내주시면 검토 후 회원 제한 등의 조치를 하겠습니다.\n\n그 외의 경우, [고객센터 - 문의하기]에서 분류를 [신고]로 선택하시고, 문의 주시면 답변드리겠습니다.",
  },
  {
    id: 12,
    active : false ,
    category: "사이트 이용",
    question: "상세주소 입력을 했을 때 좋은 점이 무엇인가요?",
    answer:
      "상세주소를 입력하시면, 지도 검색 시 업체 및 매물의 정확한 위치가 표시됩니다.\n상세주소가 입력되어 있지 않은 경우, 해당 지역의 설정된 위치에 일괄 표시됩니다.",
  },
  {
    id: 13,
    active : false ,
    category: "사이트 이용",
    question: "허위 매물등의 사기 피해를 입었습니다. 어떻게 해야 할까요?",
    answer:
      "스크랩마켓은 허위매물 등 사기 피해 복구를 위해 최선의 노력을 하겠습니다. 경찰에서 요청받는 정보 제공 등, 협조를 아끼지 않겠습니다.",
  },
  {
    id: 14,
    active : false ,
    category: "사이트 이용",
    question: "게시물 검색 시 필터 사용이 어렵습니다.",
    answer:
      "필터는 해당 매물 게시판의 왼편에 있고, 클릭 시 세부 필터들을 선택하실 수 있습니다.\n1. 광역시, 도 등의 경우 1개만 선택하실 수 있습니다.\n2. 필터 항목이 미기입된 경우, 필터를 설정하면 노출되지 않습니다.\n3. 필터 항목의 '전체' 또는 모든 항목을 클릭하시는 경우, 항목이 기입된 모든 매물이 검색 결과로 나옵니다.",
  },
  {
    id: 15,
    active : false ,
    category: "사이트 이용",
    question: "사진은 어떻게 업로드 하나요?",
    answer:
      "이미지 업로드 부분을 클릭하시면 8장까지 이미지 파일을 업로드하실 수 있습니다.",
  },
  {
    id: 16,
    active : false ,
    category: "사이트 이용",
    question: "게시글에 등록된 이미지가 잘리거나 왜곡됩니다.",
    answer:
      "등록완료된 게시글 상세페이지에서 이미지를 클릭하시면 큰 화면으로 이미지를 확인하실 수 있습니다.",
  },
  {
    id: 17,
    active : false ,
    category: "사이트 이용",
    question:
      "게시물을 '구매 중, '판매완료' 등 상태를 변경하려면 어떻게 하나요?",
    answer:
      "회원님 본인의 게시글 상세 페이지에서 '판매(구매)완료 처리하기' 버튼을 클릭하시면 됩니다.\n[마이페이지 - 판매관리 또는 구매관리] 페이지에서도 하실 수 있습니다.",
  },
  {
    id: 18,
    active : false ,
    category: "사이트 이용",
    question: "게시한 매물들은 어떻게 관리하나요?",
    answer:
      "[마이페이지 - 판매관리 또는 구매관리]에서 업로드하신 매물들을 한 번에 보실 수 있습니다.",
  },
  {
    id: 19,
    active : false ,
    category: "사이트 이용",
    question: "다른 회원과 대화를 하려면 어떻게 해야되나요?",
    answer:
      "1. 게시글 상단의 [쪽지] 버튼을 클릭하시면, 족지 보내기 페이지가 열리고, 해당 게시글 작성자와 대화하실 수 있습니다.\n2. 메인 페이지의 우측 상단에 있는 [쪽지함] 버튼을 클릭하여 [쪽지함]에서 [새 쪽지 보내기] 버튼을 클릭하세요. [새 쪽지 보내기] 페이지에서 쪽지를 받으실 회원님의 닉네임을 입력하시고, 내용을 입력하신 후 대화가 시작됩니다.",
  },
  {
    id: 20,
    active : false ,
    category: "계정 관련",
    question: "개명을 하여 회원정보의 이름을 수정하고 싶습니다.",
    answer:
      "개명하신 경우, 로그인 후 [마이페이지 - 회원정보관리]에서 본인인증 과정을 거친 후 이름을 변경하실 수 있습니다. 변경하신 데에 문제가 있으시다면, [고객센터 - 문의하기] 또는 이메일, 전화 등 연락 주시면 도와드리겠습니다. 서류 등 인증에 필요한 부분을 요청드릴 수 있습니다.",
  },
  {
    id: 21,
    active : false ,
    category: "계정 관련",
    question: "아이디, 비밀번호를 잊어버렸습니다.",
    answer:
      "아이디 및 비밀번호 찾기 방법은\n로그인 페이지 하단의 [아이디 찾기] [비밀번호 찾기]\n\n계속해서 오류가 발생하는 경우 고객센터의 이메일 또는 전화번호로 문의 바랍니다.",
  },
  {
    id: 22,
    active : false ,
    category: "계정 관련",
    question: "회원정보를 변경하고 싶습니다.",
    answer:
      "회원 정보 변경 방법은\n로그인 후 [마이페이지 - 회원정보관리]에서 직접 수정하실 수 있습니다.\n* 수정 불가 항목: 아이디, 닉네임",
  },
  {
    id: 23,
    active : false ,
    category: "계정 관련",
    question: "닉네임을 바꾸고 싶습니다.",
    answer:
      "닉네임은 변경하실 수 없습니다. 하지만, 오타 혹은 피치 못할 사정이 있으시다면, 고객센터의 문의하기로 문의 주세요.",
  },
  {
    id: 24,
    active : false ,
    category: "계정 관련",
    question: "회원가입 제한사항이 있나요?",
    answer:
      "내국인만 가입하실 수 있으며, 하나의 연락처(휴대전화 인증)로 하나의 아이디만 생성 가능합니다.",
  },
  {
    id: 25,
    active : false ,
    category: "계정 관련",
    question: "회원 탈퇴 후, 재가입이 가능한가요?",
    answer: "네, 가입 제한 사항이 없다면 탈퇴 후 14일부터 재가입이 가능합니다.",
  },
  {
    id: 26,
    active : false ,
    category: "계정 관련",
    question: "회원 탈퇴를 하려면 어떻게 해야되나요?",
    answer:
      "고객센터로 문의 [화면 하단 고객센터 - 문의하기] 주세요. 전화 문의로는 본인 확인이 불가하여 어렵습니다. 인증에 필요한 서류를 요청드릴 수 있습니다.",
  },
  {
    id: 27,
    active : false ,
    category: "계정 관련",
    question: "아이디를 여러 개 가입할 수가 있나요?",
    answer:
      "한 개의 휴대폰 번호 및 주민등록번호로 한 개의 아이디만 가입하실 수 있습니다.",
  },
  {
    id: 28,
    active : false ,
    category: "계정 관련",
    question: "로그인이 안됩니다.",
    answer:
      "아이디 비밀번호를 확인해주시고, [로그인 화면 - 아이디 찾기 또는 비밀번호 찾기]에서 확인해주세요.\n계속 로그인이 안되실 경우, 이메일로 문의 주시면 답변 드리겠습니다.",
  },
  {
    id: 29,
    active : false ,
    category: "계정 관련",
    question: "이용제한 상태로 로그인이 되지 않습니다.",
    answer:
      "탈퇴한 경우\n회원탈퇴를 하신 경우, 재가입에 제한이 있을 수 있습니다.\n\n신고 및 이용 규칙에 어긋난 경우\n악의적인 이용 내역이 있거나, 신고된 내용이 규칙에 어긋나는 것으로 판단되는 경우, 이용에 제한을 받게 됩니다. 고객센터로 문의 주세요.",
  },
  {
    id: 30,
    active : false ,
    category: "계정 관련",
    question: "외국인도 가입할 수 있나요?",
    answer: "외국인은 가입이 제한됩니다.",
  },
  {
    id: 31,
    active : false ,
    category: "계정 관련",
    question: "회원가입 시 휴대폰 본인인증에 계속 실패합니다.",
    answer:
      "가입하려는 본인 명의의 휴대폰이 아닌 경우\n외국인, 군용 명의로 된 휴대폰의 경우\n개통 신청 시 '개인정보유출방지' 서비스를 신청한 휴대폰인 경우\n\n본인인증이 되지 않습니다.",
  },
  {
    id: 32,
    active : false ,
    category: "계정 관련",
    question: "본인인증 시 이미 아이디가 있다고 하며 인증이 되지 않아요.",
    answer:
      "본인인증 아이디는 1개만 가입할 수 있습니다. 하나의 휴대폰 번호와 이름에 하나의 아이디만 만들 수 잇습니다.\n가입하신 적이 없으신 경우, 이메일 및 전화 등으로 문의주세요.",
  },
  {
    id: 33,
    active : false ,
    category: "계정 관련",
    question: "비밀번호를 변경하고 싶습니다.",
    answer: "[마이페이지 - 회원정보관리] 에서 비밀번호를 변경하실 수 있습니다.",
  },
  {
    id: 34,
    active : false ,
    category: "계정 관련",
    question: "이용약관을 확인하고 싶습니다.",
    answer:
      "페이지 좌측 상단의 [이용약관]을 클릭하시면, 상세 내용을 확인하실 수 있습니다.",
  },
  {
    id: 35,
    active : false ,
    category: "카테고리",
    question: "카테고리별로 어떤 차이점이 있나요?",
    answer:
      "카테고리별로 매물이 등록되며, 해당 카테고리마다 매물정보, 필터 등의 항목이 다릅니다.",
  },
  {
    id: 36,
    active : false ,
    category: "카테고리",
    question:
      "카테고리 게시글 등록의 항목에 기입하고 싶은 종류의 항목이 없습니다.",
    answer:
      "항목이 없는 내용은 제목 또는 상세내용에 기입하시면 제목/내용 검색, 통합검색 시 결과에 포함됩니다.",
  },

  {
    id: 37,
    active : false ,
    category: "개인정보",
    question: "회원 탈퇴 시 회원 정보는 언제 삭제되나요?",
    answer:
      "일반적인 회원탈퇴 시, 회원탈퇴 후 5일 이내 또는 법령에 따른 보존기간 후 삭제됩니다.\n신고나 이용 상의 위반으로 탈퇴 조치된 경우, 악의적인 이용을 위한 재가입을 막기 위해서 회원정보(ID, 휴대폰번호, 이메일, 전화번호, 생년월일, 부정거래사유, 탈퇴 시 회원 상태값)를 1년간 보유할 수 있습니다.",
  },
  {
    id: 38,
    active : false ,
    category: "개인정보",
    question: "이용되는 개인정보는 어디에서 확인할 수 있나요?",
    answer:
      "페이지 좌측 상단의 [개인정보처리방침]을 클릭하시면, 상세 내용을 확인하실 수 있습니다.",
  },
  {
    id: 39,
    active : false ,
    category: "개인정보",
    question: "생년월일, 휴대폰번호 등 개인정보는 어떻게 이용되나요?",
    answer: "가입 시 본인 인증 외에는 사용되지 않습니다.",
  },
];
