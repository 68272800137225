"use client"

import "./BusinessCard.css";
import { BusinesscardInfo } from "../../../api/User";
import { Businesscard } from "../../../api/User";
import { useEffect , useState } from "react";

function BusinessCard({data}:{data:number | undefined}) {

    const [user, setUser] = useState<Businesscard>();

    useEffect(() => {
        if(data != undefined){
            const fetchData = async () => {
                try {
                  const response = await BusinesscardInfo(data);
                  setUser(response);      
                } catch (error) {
                }
              }
              fetchData()
        }
    },[data]);

    
    return (
        <div className="ScrapMetalInfoSecond">
            {/* 로고 */}
            <div className="BusinessCardLogo">
                {user?.logo === "비공개" || user?.logo === ""?
                    <img src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Icon/businesscardLogo.svg" className="Logo" />
                    :
                    <img src= {user?.logo} className="Logo" />
                }
            </div>

            {/* 명함정보 */}
            <div className="BusinessCardInfoList">
                {/* 닉네임 */}
                <div className="BusinessCardfirst">
                    <div className="BusinessCardfirstImg">
                        <img src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Icon/account_circle.svg" />
                    </div>
                    <div className="NicknameTotal">
                        <div className="NicknameFirst">
                            <p className="UserInfoFix">닉네임</p>
                            {user?.nicknaem === "비공개" || user?.nicknaem === "" ?
                            <p className="UserInfo">비공개</p>
                            :
                            <p className="UserInfo">{user?.nicknaem}</p>
                            }
                        </div>
                        <div className="NicknameFirst">
                            <p className="UserInfoFix">이름</p>
                            {user?.name === "비공개"  || user?.name === "" ?
                            <p className="UserInfo">비공개</p>
                                :
                            <p className="UserInfo">{user?.name}</p>
                            }
                        </div>
                        <div className="NicknameFirst">
                            <p className="UserInfoFix">직급</p>
                            {user?.position === "비공개" || user?.position === "" ?
                            <p className="UserInfo">비공개</p>
                            :
                            <p className="UserInfo">{user?.position}</p>
                            }
                        </div>
                    </div>
                </div>

                {/* 지역 */}
                <div className="BusinessCardfirst">
                    <div className="BusinessCardsecondImg">
                        <img src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Icon/userLocation.svg" />
                    </div>
                    <div className="NicknameTotal">
                        <div className="NicknameFirst">
                            <p className="UserInfoFix">회사명</p>
                            {user?.bizName === "비공개" || user?.bizName === ""?
                                <p className="UserInfo">비공개</p>
                                :
                                <p className="UserInfo">{user?.bizName}</p>
                            }
                        </div>
                        <div className="NicknameFirst">
                            <p className="UserInfoFix">지역</p>
                            <p className="UserInfo">{user?.metro}&nbsp;&nbsp;&nbsp;{user?.shigungu}</p>
                        </div>
                        <div className="NicknameFirst">
                            <p className="UserInfoFix">상세주소</p>
                            {user?.detail === "비공개" || user?.detail === "" ?
                                <p className="UserInfo">비공개</p>
                                :
                                <p className="UserInfo">{user?.detail}</p>                                
                            }
                        </div>
                    </div>
                </div>

                <div className="BusinessCardfirst">
                    <div className="BusinessCardthirdImg">
                        <img src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Icon/userPhone.svg" />
                    </div>
                    <div className="NicknameTotal">
                        <div className="NicknameFirst">
                            <p className="UserInfoFix">휴대전화</p>
                            {user?.cell === "비공개" || user?.cell === ""?
                                <p className="UserInfoPhone">휴대전화</p>
                                :
                                <p className="UserInfoPhone">{user?.cell}</p>
                            }
                            <div className="UserInfoCertify">
                                {user?.cell === user?.tel?
                                    <p>인증</p>:<p></p>
                                }
                            </div>
                        </div>
                        <div className="NicknameFirst">
                            <p className="UserInfoFix">전화</p>
                            {user?.tel === "비공개" || user?.tel === "" ?
                                <p className="UserInfo">비공개</p>
                                :
                                <p className="UserInfo">{user?.tel}</p>
                            }
                        </div>
                        <div className="NicknameFirst">
                            <p className="UserInfoFix">이메일</p>
                            {user?.email === "비공개" || user?.email === "" ?
                                <p className="UserInfo">비공개</p>
                                :
                                <p className="UserInfo">{user?.email}</p>
                            }
                        </div>
                    </div>                    
                </div>

            </div>

        </div>
    );
}


export default BusinessCard