"use client"

import ScrapMetal from "../../ScrapMetal/[slug]/page"

type PageParams = {
  slug: string;
}

export default function Paper({ params  }: { params: string} ) {


  return (
    <>
     <ScrapMetal params = {params} category="파지" api_category = "paper" />
    </>
  )
}