"use client"

import ScrapMetal from "../../ScrapMetal/[slug]/page"

type PageParams = {
  slug: string;
}

export default function Nonferrous({ params  }: { params: string} ) {


  return (
    <>
     <ScrapMetal params = {params} category="비철" api_category = "bicheol" />
    </>
  )
}