import { useParams } from "react-router-dom";
import Header from "../../Common/Header";
import Footer from "../../Common/Footer";
import styled from "styled-components";
import { TermsOfServiceJSON } from "./TermsOfServiceJSON";
import { PrivacyPolicyJSON } from "./PrivacyPolicyJSON";

function TermsUse() {

    const { id } = useParams<{ id: string }>();

    return (
        <>
            <Header />
            <UseTermBox>
                <UseTermInner>
                    <UseTermTitle>
                        <UseTermTitleInner>
                            {id === "use" ?
                                "스크랩마켓 이용약관"
                                :
                                "스크랩마켓 개인정보처리방침"
                            }
                        </UseTermTitleInner>
                    </UseTermTitle>
                    <UseTermContent>
                        {id === "use" ?
                            <>
                                {TermsOfServiceJSON.map((e, i) => {
                                    return (
                                        <div
                                            key={e.id}
                                        >
                                            <h3>{e.title}</h3>
                                            <p>{e.content}</p>
                                        </div>
                                    );
                                })}
                            </> :
                            <>
                                {PrivacyPolicyJSON.map((e, i) => {
                                    return (
                                        <div
                                            key={e.id}
                                        >
                                            <h3>{e.title}</h3>
                                            <p>{e.content1}</p>
                                            {e.img && <img src={e.img} alt="개인정보처리방침 표" />}
                                            {e.content2 && <p>{e.content2}</p>}
                                        </div>
                                    );
                                })}
                            </>
                        }

                    </UseTermContent>
                </UseTermInner>
            </UseTermBox >
            <Footer />
        </>
    );
}

export default TermsUse;


const UseTermBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:700px;
    @media all and (max-width:1080px) {
        margin-top:80px;    
    }
`;

const UseTermInner = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    width:1080px;
    height:100%; 
    @media all and (max-width:1080px) {
        width:90%;    
    }
`;

const UseTermTitle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width:1080px;
    height:33px;
    margin-top:20px; 
    font-size: 24px;
    font-weight: 700;
    @media all and (max-width:1080px) {
        width:100%;    
    }
`;

const UseTermTitleInner = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width:1000px;
    height:33px;
    @media all and (max-width:1080px) {
        width:100%;    
    }
`;

const UseTermContent = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width:1000px;
    height:800px;
    margin-top:20px; 
    font-size: 14px;
    font-weight: 500;
    border:2px solid rgba(153, 153, 153, 1);
    overflow:auto;
    flex-direction: column;
    &::-webkit-scrollbar {
        width:4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: black; /* 스크롤바의 색상 */
        border-radius:5px;
    }
    &::-webkit-scrollbar-track {
        background-color: white; /* 스크롤바의 색상 */
    }       
    @media all and (max-width:1080px) {
        width:100%; 
    }
    div{
        white-space: pre-wrap;
        padding:8px;
    }
    img{
        width: 100%;
      }
`;




// styled(Button)`
//   // <Button> React 컴포넌트에 스타일 정의
// `;