"use client"

import "./page.css";
import { useState, useEffect } from "react";
import { getNoticeDetail } from "../../api/Product";
import { ApiResponse } from "../../api/Product";
import { useNavigate } from "react-router-dom";
import Header from "../../Common/Header";
import Footer from "../../Common/Footer";

function Post() {

  const navigate = useNavigate();

  /* 카테고리 변경시마다 데이터 변경 */
  const DataChange = (a: string, b: string) => {
    setProduct(a)
    setProductApi(b)
    setProducts(undefined);
  };

  const Locate = (a:string ,
    first:number , second:number , third:number , four:number , five:number,
    six:number , seven:number , eight:number , nine:number , ten:number,
    first_first:number , first_second:number , first_third:number , first_four:number , first_five:number,
    first_six:number , first_seven:number , first_eight:number , first_nine:number , first_ten:number,
    ) => {
    
    switch (a) {

      case "고철":
        navigate(`/Product/ScrapMetal/${first}`)
        break;

      case "비철":
        navigate(`/Product/Nonferrous/${second}`)
        break;
      
      case "작업철":
          navigate(`/Product/WorkCheol/${third}`)
          break;
      
      case "PCB":
          navigate(`/Product/PCB/${four}`)
          break;
          
      case "플라스틱":
          navigate(`/Product/Plastic/${five}`)
          break;
      
      case "전선":
          navigate(`/Product/Wire/${six}`)
          break;
            
      case "헌옷":
          navigate(`/Product/Cloth/${seven}`)
          break;
      
      case "파지":
          navigate(`/Product/Paper/${eight}`)
          break;

      case "특수금속":
          navigate(`/Product/SpecialMetal/${nine}`)
          break;
      
      case "기계부품":
          navigate(`/Product/Machine/${ten}`)
          break;
      
      case "마대":
          navigate(`/Product/Madae/${first_first}`)
          break;
      
      case "타이어":
          navigate(`/Product/Tire/${first_second}`)
          break;
      
      case "전자제품":
          navigate(`/Product/Electronic/${first_third}`)
          break;
      
      case "유리":
          navigate(`/Product/Glass/${first_four}`)
          break;
                
      case "철거":
          navigate(`/Product/Demolition/${first_five}`)
          break;
      
      case "화물차":
          navigate(`/Product/Truck/${first_six}`)
          break;
      
      case "부동산":
          navigate(`/Product/Estate/${first_seven}`)
          break;
      
      case "구인구직":
          navigate(`/Product/Jobboard/${first_eight}`)
          break;
      
      case "폐기물":
          navigate(`/Product/Trash/${first_nine}`)
          break;
      
      case "기타":
          navigate(`/Product/Etc/${first_ten}`)
          break;
        
      default:


    }
  }

  /* 게시물 정보 및 API_Info */
  const ProductList = [
    { category: "추천매물", api_name: "" }, { category: "고철", api_name: "gocheol" }, { category: "비철", api_name: "bicheol" },
    { category: "작업철", api_name: "workcheol" }, { category: "PCB", api_name: "pcb" }, { category: "플라스틱", api_name: "plastic" },
    { category: "전선", api_name: "wire" }, { category: "헌옷", api_name: "fabric" }, { category: "파지", api_name: "paper" },
    { category: "특수금속", api_name: "specialmetal" }, { category: "기계부품", api_name: "machine" }, { category: "마대", api_name: "madae" },
    { category: "타이어", api_name: "tire" }, { category: "전자제품", api_name: "electronic" }, { category: "유리", api_name: "glass" },
    { category: "철거", api_name: "demolition" }, { category: "화물차", api_name: "truck" }, { category: "부동산", api_name: "realestate" },
    { category: "구인구직", api_name: "jobboard" }, { category: "폐기물", api_name: "trash" }, { category: "기타", api_name: "etc" },
  ]
  const [product, setProduct] = useState<string>("추천매물")
  const [productApi, setProductApi] = useState<string>("recommend")
  const [products, setProducts] = useState<ApiResponse>();

  useEffect(() => {
    if (product != "추천매물") {
      const fetchData = async () => {
        try {
          const response = await getNoticeDetail(productApi);
          setProducts(response);
        } catch (error) {
        }
      };

      fetchData()
    }
    else {

    }

  }, [product , productApi]);

  return (
    <>
    <Header/>
      <div className="ProductTotalBox">
        {/* 제목 */}
        <div className="InnerBox">
          <p className="Title">매물 찾기</p>
        </div>

        {/* 카테고리 리스트 */}
        <div className="CategoeyBox">
          {ProductList.map((a, index) => {
            return (
              <div key={a.category} className="CategoeyInnerBox">
                {a.category === product ?
                  <div className="CategoeyBorderActive">
                    <img src={`https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Category/cate_${index}_on.png`} className="ActiveImg" />
                    {/* {a.category === "추천매물" ?
                      <img src={`https://s3.ap-northeast-2.amazonaws.com/scrapmarket-2.0.0/Icon/ddabong-active-icon.svg`} className="ActiveImg" />
                      :
                      <img src={`https://scrapmarket.s3.ap-northeast-2.amazonaws.com/App/Category/cate${index}_on.png`} className="ActiveImg" />
                    } */}
                    {a.category}
                  </div>
                  :
                  <div className="CategoeyBorder" onClick={() => DataChange(a.category, a.api_name)} >
                    <img src={`https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Category/cate_${index}_off.png`} className="ActiveImg" />
                    {/* {a.category === "추천매물" ?
                      <img src={`https://s3.ap-northeast-2.amazonaws.com/scrapmarket-2.0.0/Icon/ddabong-inactive-icon.svg`} className="ActiveImg" />
                      :
                      <img src={`https://scrapmarket.s3.ap-northeast-2.amazonaws.com/App/Category/cate${index}_off.png`} className="ActiveImg" />
                    } */}
                    {a.category}
                  </div>
                }
              </div>
            )
          })}
        </div>

        {/* 게시물 리스트 */}
        <div className="PostList">
          {products?.results.map((a) => {
            return (
              <div key={a.category2} onClick={() => Locate(product ,
              a.gocheol_id ,  a.bicheol_id , a.workcheol_id , a.pcb_id , a.plastic_id,
              a.wire_id ,  a.fabric_id , a.paper_id , a.specialmetal_id , a.machine_id,             
              a.madae_id ,  a.tire_id , a.electronic_id , a.glass_id , a.demolition_id,
              a.truck_id ,  a.realestate_id , a.jobboard_id , a.trash_id , a.etc_id             
              )}>
                {product === "추천매물" ?
                  <div className="PostSoloList">
                    추천매물
                  </div>
                  :
                  <div className="PostSoloList">
                    <img src={a.image_1} className="SoloListImg" />
                    <p className="SoloListTitle">{a.title}</p>
                    <div className="ContentsBox">
                      <p>{product}</p>
                      <p style={{ marginLeft: "10px" }}>{a.category2}</p>
                    </div>
                    <div className="ContentsBox" style={{ marginTop: "0px" }}>
                      <p>{a.metro_city}</p>
                      <p style={{ marginLeft: "10px" }}>{a.shigungu}</p>
                    </div>
                  </div>
                }
              </div>
            )
          })}
        </div>

      </div >
    <Footer/>
    </>
  )
}

export default Post


