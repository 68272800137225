"use client"

import { useState } from 'react';
import './Dropdown.css';

interface DropdownProps {
    onSelectOption: (option: string) => void;
  }

function Dropdown({onSelectOption}: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('전체');

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option:any) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelectOption(option);
  };

  const options = ['전체', '계정 관련', '사이트 이용', '카테고리', '개인정보'];

  return (
    <div className="dropdown">
      <div className="dropdown-header" onClick={toggleDropdown}>
        <p>{selectedOption}</p>
        <span className={isOpen ? 'arrow-up' : 'arrow-down'}></span>
      </div>
      {isOpen && (
        <div className="dropdown-menu">
          {options.map((option) => (
            <div 
              key={option} 
              className="dropdown-item" 
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
