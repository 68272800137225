"use client"

import "./globals.css";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

function Header(props: any) {

    const [open, setOpen] = useState<boolean>(false)
    const [mobile, setMobile] = useState<boolean>(false);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const CustomerList = ['매물 찾기', '공지사항', '자주하는 질문', '문의하기', '앱 다운로드']
    const navigate = useNavigate();

    /* isMobile 의값에 따라 모바일여부 판정 */
    useEffect(() => {
        checkResize();
    }, [isMobile]);

    /* 모바일에서 활성화 */
    const dataChange = () => {
        setOpen(!open);
        if (open) {
            document.body.style.overflow = "auto";
        }
        else {
            document.body.style.overflow = "hidden";
        }
    };

    /* 모바일 PC 검증 */
    const checkResize = () => {
        document.body.style.overflow = "auto";
        if (isMobile) {
            setMobile(true);
        } else {
            setMobile(false);
            setOpen(false)
        }
    };

    /* 모바일에서 화면전환 */
    const Locate = (a: string) => {
        setOpen(false)
        document.body.style.overflow = "auto";
        if (a === "매물 찾기") {
            navigate("/Product")
        }
        else if (a === "공지사항") {
            navigate("/CustomerService/Notice")
        }
        else if (a === "자주하는 질문") {
            navigate("/CustomerService/Question")
        }
        else if (a === "앱 다운로드") {
            window.open("https://play.google.com/store/apps/details?id=com.scrapmarket.scrapmarket")
        }
        else if (a === "문의하기") {
            navigate("/CustomerService/Inquire")
        }
        /* 문의하기 .  */
    }

    /* 모바일에서 로고 */
    const MainLocate = () => {
        navigate("/")
        setOpen(false)
    }

    const BackClose = () => {
        setOpen(false)
        document.body.style.overflow = "auto";
    }

    return (
        <div className="headerTotalBox">
            {open ?
                <header className="headerBoxOpen">
                    <div className="headerInnerBoxOpen">
                        {/* Header-Logo */}
                        <div className="headerFirstBox" onClick={() => MainLocate()}>
                            {isMobile ?
                                <img
                                    width={206}
                                    height={30}
                                    src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Common/Logo_Mobile.png"
                                    alt="Logo"
                                ></img>
                                :
                                <img
                                    width={223}
                                    height={62}
                                    src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Common/Logo.png"
                                    alt="Logo"
                                ></img>
                            }
                        </div>

                        {/* Header-Option */}
                        <div className="headerSecondBox">
                            {/* Hambug */}
                            <nav className="Toggle" onClick={() => dataChange()}>
                                <div id="menuToggle">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </nav>

                            {/* 우측리스트 */}
                            <Link to="/" className="headerSecondFirst">매물찾기</Link>
                            <div className="headerSecondSecond">
                                <Link to={`/CustomerService/First`} className="headerSecondSecondText">공지사항</Link>
                                <Link to="/" className="headerSecondSecondText">자주하는 질문</Link>
                                <Link to="/" className="headerSecondSecondText">문의하기</Link>
                            </div>
                        </div>
                    </div>
                    {CustomerList.map((a) => {
                        return (
                            <div className="moOpenBox" key={a}
                                onClick={() => Locate(a)}>
                                {a === '문의하기' ?
                                    <div className="moOpenInner">
                                        {a}
                                        <img
                                            alt="kakao"
                                            style={{ marginLeft: 15 }}
                                            width={24} height={24}
                                            src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Customer/Inquire_kakao.png" />
                                    </div>
                                    :
                                    a === '앱 다운로드' ?
                                        <div className="moOpenInner">
                                            {a}
                                            <img
                                                alt="Header_Locate"
                                                style={{ marginLeft: 15 }}
                                                width={24} height={24}
                                                src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Common/Common_Header_Locate.png"
                                            />
                                        </div>
                                        :
                                        <div className="moOpenInner">
                                            {a}
                                        </div>
                                }
                            </div>
                        )
                    })}
                    <div className="modaleffect" onClick={() => BackClose()}>
                    </div>
                </header>
                :
                <header className="headerBox">
                    <div className="headerInnerBox">
                        {/* Header-Logo */}
                        <Link to="/" className="headerFirstBox" >
                            {isMobile ?
                                <img
                                    width={206}
                                    height={30}
                                    src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Common/Logo_Mobile.png"
                                    alt="Logo"
                                ></img>
                                :
                                <img
                                    width={223}
                                    height={62}
                                    src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Common/Logo.png"
                                    alt="Logo"
                                ></img>
                            }
                        </Link>

                        {/* Header-Option */}
                        <div className="headerSecondBox">
                            {/* Hambug */}
                            <nav className="Toggle" onClick={() => dataChange()}>
                                <div id="menuToggle">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </nav>

                            {/* 우측리스트 */}
                            <Link to="/Product" className="headerSecondFirst">매물찾기</Link>
                            <div className="headerSecondSecond">
                                <Link to={`/CustomerService/Notice`} className="headerSecondSecondText">공지사항</Link>
                                <Link to="/CustomerService/Question" className="headerSecondSecondText">자주하는 질문</Link>
                                <Link to="/CustomerService/Inquire" className="headerSecondSecondText">문의하기</Link>
                            </div>

                        </div>

                        {/* Header-Locate */}
                        <div className="headerSecondLocate">
                            <Link to='https://play.google.com/store/apps/details?id=com.scrapmarket.scrapmarket' className="LocateApp">
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <img
                                        width={20}
                                        height={20}
                                        style={{ marginRight: "10px" }}
                                        src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Common/PlayStore_logo.png"
                                        alt="Logo"
                                    />
                                    <p>앱 다운로드</p>
                                    <img
                                        width={16}
                                        height={16}
                                        style={{ marginLeft: "10px" }}
                                        src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Common/Common_Playstore_Download.png"
                                        alt="Download"
                                    />
                                </div>
                            </Link>
                        </div>

                    </div>
                </header>
            }
        </div>

    )
}

export default Header


