"use client"

import ScrapMetal from "../../ScrapMetal/[slug]/page"

type PageParams = {
  slug: string;
}

export default function Jobboard({ params  }: { params: string} ) {

  return (
    <>
     <ScrapMetal params = {params} category="구인구직" api_category = "jobboard" />
    </>
  )
}