"use client"

import { useEffect, useRef, useState } from 'react';

declare global {
    interface Window {
        naver: any;
    }
}

const NaverMap = (
    { lat, lng, metro, shigungu }:
        { lat: string | undefined, lng: string | undefined, metro: string | undefined, shigungu: string | undefined }
) => {
    const mapElement = useRef<HTMLDivElement>(null);
    const [pointX, setPointX] = useState<string>();
    const [pointY, setPointY] = useState<string>();

    useEffect(() => {
        const script = document.createElement('script');
        script.src = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=69klmffwmx`;
        script.onload = () => {
            if (mapElement.current) {
                if (metro != undefined) {
                    if (lat === '') {
                        if (metro === "서울") {
                            if (shigungu === "강남구") {
                                setPointX("37.517374");
                                setPointY("127.047168");
                            }
                            else if (
                                shigungu === "강동구"
                            ) {
                                setPointX("37.530094");
                                setPointY("127.12388");
                            } else if (
                                shigungu === "강북구"
                            ) {
                                setPointX("37.63975");
                                setPointY("127.025574");
                            } else if (
                                shigungu === "강서구"
                            ) {
                                setPointX("37.550842");
                                setPointY("126.849413");
                            } else if (
                                shigungu === "관악구"
                            ) {
                                setPointX("37.478164");
                                setPointY("126.951435");
                            } else if (
                                shigungu === "광진구"
                            ) {
                                setPointX("37.538551");
                                setPointY("127.082374");
                            } else if (
                                shigungu === "구로구"
                            ) {
                                setPointX("37.495519");
                                setPointY("126.888292");
                            } else if (
                                shigungu === "금천구"
                            ) {
                                setPointX("37.456948");
                                setPointY("126.895431");
                            } else if (
                                shigungu === "노원구"
                            ) {
                                setPointX("37.653998");
                                setPointY("127.056343");
                            } else if (
                                shigungu === "도봉구"
                            ) {
                                setPointX("37.668713");
                                setPointY("127.047298");
                            } else if (
                                shigungu === "동대문"
                            ) {
                                setPointX("37.574447");
                                setPointY("127.039815");
                            } else if (
                                shigungu === "동작구"
                            ) {
                                setPointX("37.512489");
                                setPointY("126.939659");
                            } else if (
                                shigungu === "마포구"
                            ) {
                                setPointX("37.56621");
                                setPointY("126.901311");
                            } else if (
                                shigungu === "서대문"
                            ) {
                                setPointX("37.579353");
                                setPointY("126.936432");
                            } else if (
                                shigungu === "서초구"
                            ) {
                                setPointX("37.483528");
                                setPointY("127.03277");
                            } else if (
                                shigungu === "성동구"
                            ) {
                                setPointX("37.563471");
                                setPointY("127.036772");
                            } else if (
                                shigungu === "성북구"
                            ) {
                                setPointX("37.589368");
                                setPointY("127.01666");
                            } else if (
                                shigungu === "송파구"
                            ) {
                                setPointX("37.514375");
                                setPointY("127.105872");
                            } else if (
                                shigungu === "양천구"
                            ) {
                                setPointX("37.517093");
                                setPointY("126.866677");
                            } else if (
                                shigungu === "영등포"
                            ) {
                                setPointX("37.526348");
                                setPointY("126.896413");
                            } else if (
                                shigungu === "용산구"
                            ) {
                                setPointX("37.53235");
                                setPointY("126.990475");
                            } else if (
                                shigungu === "은평구"
                            ) {
                                setPointX("37.602793");
                                setPointY("126.929099");
                            } else if (
                                shigungu === "종로구"
                            ) {
                                setPointX("37.573575");
                                setPointY("126.97889");
                            } else if (
                                shigungu === "중구"
                            ) {
                                setPointX("37.563719");
                                setPointY("126.997487");
                            } else {
                                setPointX("37.606477");
                                setPointY("127.09315");
                            }
                        }
                        else if (metro === "부산") {
                            if (
                                shigungu === "강서구"
                            ) {
                                setPointX("35.212188");
                                setPointY("128.98076");
                            } else if (
                                shigungu === "금정구"
                            ) {
                                setPointX("35.242977");
                                setPointY("129.092086");
                            } else if (
                                shigungu === "기장군"
                            ) {
                                setPointX("35.244569");
                                setPointY("129.222243");
                            } else if (
                                shigungu === "남구"
                            ) {
                                setPointX("35.136582");
                                setPointY("129.084319");
                            } else if (
                                shigungu === "동구"
                            ) {
                                setPointX("35.136582");
                                setPointY("129.084319");
                            } else if (
                                shigungu === "동래구"
                            ) {
                                setPointX("35.1964101");
                                setPointY("129.093604");
                            } else if (
                                shigungu === "부산진"
                            ) {
                                setPointX("35.1964101");
                                setPointY("129.093604");
                            } else if (
                                shigungu === "북구"
                            ) {
                                setPointX("35.1971446");
                                setPointY("128.990152");
                            } else if (
                                shigungu === "사상구"
                            ) {
                                setPointX("35.1523642");
                                setPointY("128.991333");
                            } else if (
                                shigungu === "사하구"
                            ) {
                                setPointX("35.1045133");
                                setPointY("128.974718");
                            } else if (
                                shigungu === "서구"
                            ) {
                                setPointX("35.0976017");
                                setPointY("129.023894");
                            } else if (
                                shigungu === "수영구"
                            ) {
                                setPointX("35.1454035");
                                setPointY("129.112912");
                            } else if (
                                shigungu === "연제구"
                            ) {
                                setPointX("35.1762578");
                                setPointY("129.079631");
                            } else if (
                                shigungu === "영도구"
                            ) {
                                setPointX("35.0910736");
                                setPointY("129.067917");
                            } else if (
                                shigungu === "중구"
                            ) {
                                setPointX("35.1062826");
                                setPointY("129.032355");
                            } else {
                                setPointX("35.1629981");
                                setPointY("129.163735");
                            }
                        }
                        else if (metro === "대구") {
                            if (
                                shigungu === "남구"
                            ) {
                                setPointX("35.84584");
                                setPointY("128.597676");
                            } else if (
                                shigungu === "달서구"
                            ) {
                                setPointX("35.8297394");
                                setPointY("128.532618");
                            } else if (
                                shigungu === "달성군"
                            ) {
                                setPointX("35.7745744");
                                setPointY("128.431316");
                            } else if (
                                shigungu === "동구"
                            ) {
                                setPointX("35.8867196");
                                setPointY("128.635803");
                            } else if (
                                shigungu === "북구"
                            ) {
                                setPointX("35.8856961");
                                setPointY("128.583027");
                            } else if (
                                shigungu === "서구"
                            ) {
                                setPointX("35.8720481");
                                setPointY("128.559262");
                            } else if (
                                shigungu === "수성구"
                            ) {
                                setPointX("35.8580272");
                                setPointY("128.63052");
                            } else {
                                setPointX("35.8692507");
                                setPointY("128.606094");
                            }
                        }
                        else if (metro === "인천") {
                            if (
                                shigungu === "강화군"
                            ) {
                                setPointX("37.7473608");
                                setPointY("126.487073");
                            } else if (
                                shigungu === "계양구"
                            ) {
                                setPointX("37.5371073");
                                setPointY("126.737755");
                            } else if (
                                shigungu === "남동구"
                            ) {
                                setPointX("37.4471819");
                                setPointY("126.731268");
                            } else if (
                                shigungu === "동구"
                            ) {
                                setPointX("37.4737438");
                                setPointY("126.643254");
                            } else if (
                                shigungu === "미추홀"
                            ) {
                                setPointX("37.4635835");
                                setPointY("126.650254");
                            } else if (
                                shigungu === "부평구"
                            ) {
                                setPointX("37.5067875");
                                setPointY("126.721508");
                            } else if (
                                shigungu === "서구"
                            ) {
                                setPointX("37.5450984");
                                setPointY("126.676045");
                            } else if (
                                shigungu === "연수구"
                            ) {
                                setPointX("37.409774");
                                setPointY("126.67819");
                            } else if (
                                shigungu === "옹진군"
                            ) {
                                setPointX("37.4465587");
                                setPointY("126.63675");
                            } else {
                                setPointX("37.4735416");
                                setPointY("126.621765");
                            }

                        }
                        else if (metro === "광구") {
                            if (
                                shigungu === "광산구"
                            ) {
                                setPointX("35.1395976");
                                setPointY("126.793875");
                            } else if (
                                shigungu === "남구"
                            ) {
                                setPointX("35.1328666");
                                setPointY("126.902482");
                            } else if (
                                shigungu === "동구"
                            ) {
                                setPointX("35.1458883");
                                setPointY("126.923075");
                            } else if (
                                shigungu === "북구"
                            ) {
                                setPointX("35.1742735");
                                setPointY("126.912311");
                            } else {
                                setPointX("35.1742735");
                                setPointY("126.912311");
                            }
                        }
                        else if (metro === "대전") {
                            if (
                                shigungu === "대덕구"
                            ) {
                                setPointX("36.3465925");
                                setPointY("127.415694");
                            } else if (
                                shigungu === "동구"
                            ) {
                                setPointX("35.5053163");
                                setPointY("129.417047");
                            } else if (
                                shigungu === "서구"
                            ) {
                                setPointX("36.3550707");
                                setPointY("127.383809");
                            } else if (
                                shigungu === "유성구"
                            ) {
                                setPointX("36.3620763");
                                setPointY("127.356378");
                            } else {
                                setPointX("36.3254763");
                                setPointY("127.421373");
                            }
                        }
                        else if (metro === "울산") {
                            if (
                                shigungu === "남구"
                            ) {
                                setPointX("35.543545");
                                setPointY("129.330185");
                            } else if (
                                shigungu === "동구"
                            ) {
                                setPointX("35.5053163");
                                setPointY("129.417047");
                            } else if (
                                shigungu === "북구"
                            ) {
                                setPointX("35.5824555");
                                setPointY("129.361048");
                            } else if (
                                shigungu === "울주군"
                            ) {
                                setPointX("35.5219179");
                                setPointY("129.241352");
                            } else {
                                setPointX("35.5693733");
                                setPointY("129.332763");
                            }
                        }
                        else if (metro === "세종") {
                            setPointX("36.479931");
                            setPointY("127.289047");
                        }
                        else if (metro === "경기") {
                            if (
                                shigungu === "가평군"
                            ) {
                                setPointX("37.8313551");
                                setPointY("127.509675");
                            } else if (
                                shigungu === "고양시"
                            ) {
                                setPointX("37.6581204");
                                setPointY("126.832149");
                            } else if (
                                shigungu === "과천시"
                            ) {
                                setPointX("37.4293434");
                                setPointY("126.987474");
                            } else if (
                                shigungu === "광명시"
                            ) {
                                setPointX("37.477784");
                                setPointY("126.864371");
                            } else if (
                                shigungu === "광주시"
                            ) {
                                setPointX("37.4291906");
                                setPointY("127.254372");
                            } else if (
                                shigungu === "구리시"
                            ) {
                                setPointX("37.5942745");
                                setPointY("127.129572");
                            } else if (
                                shigungu === "군포시"
                            ) {
                                setPointX("37.3614159");
                                setPointY("126.934873");
                            } else if (
                                shigungu === "김포시"
                            ) {
                                setPointX("37.6153786");
                                setPointY("126.715665");
                            } else if (
                                shigungu === "남양주"
                            ) {
                                setPointX("37.6357378");
                                setPointY("127.216279");
                            } else if (
                                shigungu === "동두천"
                            ) {
                                setPointX("37.9034495");
                                setPointY("127.060736");
                            } else if (
                                shigungu === "부천시"
                            ) {
                                setPointX("37.5029019");
                                setPointY("126.765889");
                            } else if (
                                shigungu === "성남시"
                            ) {
                                setPointX("37.4198715");
                                setPointY("127.126405");
                            } else if (
                                shigungu === "수원시"
                            ) {
                                setPointX("37.2634355");
                                setPointY("127.02863");
                            } else if (
                                shigungu === "시흥시"
                            ) {
                                setPointX("37.3799107");
                                setPointY("126.803251");
                            } else if (
                                shigungu === "안산시"
                            ) {
                                setPointX("37.3221091");
                                setPointY("126.831552");
                            } else if (
                                shigungu === "안성시"
                            ) {
                                setPointX("37.0077973");
                                setPointY("127.279979");
                            } else if (
                                shigungu === "안양시"
                            ) {
                                setPointX("37.3945301");
                                setPointY("126.958153");
                            } else if (
                                shigungu === "양주군"
                            ) {
                                setPointX("37.7851077");
                                setPointY("127.045259");
                            } else if (
                                shigungu === "양평군"
                            ) {
                                setPointX("37.4915121");
                                setPointY("127.487869");
                            } else if (
                                shigungu === "여주시"
                            ) {
                                setPointX("37.2982138");
                                setPointY("127.637155");
                            } else if (
                                shigungu === "연천군"
                            ) {
                                setPointX("38.0964553");
                                setPointY("127.075028");
                            } else if (
                                shigungu === "오산시"
                            ) {
                                setPointX("37.1498102");
                                setPointY("127.077668");
                            } else if (
                                shigungu === "용인시"
                            ) {
                                setPointX("37.2408267");
                                setPointY("127.1775");
                            } else if (
                                shigungu === "의왕시"
                            ) {
                                setPointX("37.3448153");
                                setPointY("126.968937");
                            } else if (
                                shigungu === "의정부"
                            ) {
                                setPointX("37.7380452");
                                setPointY("127.033875");
                            } else if (
                                shigungu === "이천시"
                            ) {
                                setPointX("37.2716304");
                                setPointY("127.434283");
                            } else if (
                                shigungu === "파주시"
                            ) {
                                setPointX("37.759681");
                                setPointY("126.779909");
                            } else if (
                                shigungu === "평택시"
                            ) {
                                setPointX("36.9917265");
                                setPointY("127.112771");
                            } else if (
                                shigungu === "포천시"
                            ) {
                                setPointX("37.8949722");
                                setPointY("127.199989");
                            } else if (
                                shigungu === "하남시"
                            ) {
                                setPointX("37.539029");
                                setPointY("127.21461");
                            } else {
                                setPointX("37.199415");
                                setPointY("126.831523");
                            }
                        }
                        else if (metro === "강원") {
                            {
                                if (
                                    shigungu === "강릉시"
                                ) {
                                    setPointX("37.7519924");
                                    setPointY("128.876196");
                                } else if (
                                    shigungu === "고성군"
                                ) {
                                    setPointX("38.3806431");
                                    setPointY("128.467795");
                                } else if (
                                    shigungu === "동해시"
                                ) {
                                    setPointX("37.52479");
                                    setPointY("129.11448");
                                } else if (
                                    shigungu === "삼척시"
                                ) {
                                    setPointX("37.449832");
                                    setPointY("129.165182");
                                } else if (
                                    shigungu === "속초시"
                                ) {
                                    setPointX("38.206899");
                                    setPointY("128.591994");
                                } else if (
                                    shigungu === "양구군"
                                ) {
                                    setPointX("38.1097691");
                                    setPointY("127.990038");
                                } else if (
                                    shigungu === "양양군"
                                ) {
                                    setPointX("38.0755146");
                                    setPointY("128.619046");
                                } else if (
                                    shigungu === "영월군"
                                ) {
                                    setPointX("37.1835334");
                                    setPointY("128.461848");
                                } else if (
                                    shigungu === "원주시"
                                ) {
                                    setPointX("37.3412613");
                                    setPointY("127.918948");
                                } else if (
                                    shigungu === "인제군"
                                ) {
                                    setPointX("38.0695838");
                                    setPointY("128.170298");
                                } else if (
                                    shigungu === "정선군"
                                ) {
                                    setPointX("37.3807162");
                                    setPointY("128.660583");
                                } else if (
                                    shigungu === "철원군"
                                ) {
                                    setPointX("38.1465516");
                                    setPointY("127.313037");
                                } else if (
                                    shigungu === "춘천시"
                                ) {
                                    setPointX("37.8811024");
                                    setPointY("127.729702");
                                } else if (
                                    shigungu === "태백시"
                                ) {
                                    setPointX("37.1637391");
                                    setPointY("128.986053");
                                } else if (
                                    shigungu === "평창군"
                                ) {
                                    setPointX("37.3706672");
                                    setPointY("128.390366");
                                } else if (
                                    shigungu === "홍천군"
                                ) {
                                    setPointX("37.6967526");
                                    setPointY("127.888787");
                                } else if (
                                    shigungu === "화천군"
                                ) {
                                    setPointX("38.10602");
                                    setPointY("127.708043");
                                } else {
                                    setPointX("37.4919216");
                                    setPointY("127.985251");
                                }
                            }
                        }
                        else if (metro === "충북") {
                            if (
                                shigungu === "괴산군"
                            ) {
                                setPointX("36.8152753");
                                setPointY("127.786664");
                            } else if (
                                shigungu === "단양군"
                            ) {
                                setPointX("36.9847211");
                                setPointY("128.365796");
                            } else if (
                                shigungu === "보은군"
                            ) {
                                setPointX("36.4892046");
                                setPointY("127.729324");
                            } else if (
                                shigungu === "영동군"
                            ) {
                                setPointX("36.1745432");
                                setPointY("127.782956");
                            } else if (
                                shigungu === "옥천군"
                            ) {
                                setPointX("36.3063638");
                                setPointY("127.571256");
                            } else if (
                                shigungu === "음성군"
                            ) {
                                setPointX("36.9401025");
                                setPointY("127.690575");
                            } else if (
                                shigungu === "제천시"
                            ) {
                                setPointX("37.1323737");
                                setPointY("128.191307");
                            } else if (
                                shigungu === "증평군"
                            ) {
                                setPointX("36.7851945");
                                setPointY("127.581436");
                            } else if (
                                shigungu === "진천군"
                            ) {
                                setPointX("36.8552009");
                                setPointY("127.435556");
                            } else if (
                                shigungu === "청주시"
                            ) {
                                setPointX("36.6416091");
                                setPointY("127.489661");
                            } else {
                                setPointX("36.9907823");
                                setPointY("127.925942");
                            }
                        }
                        else if (metro === "충남") {
                            if (
                                shigungu === "계룡시"
                            ) {
                                setPointX("36.275124");
                                setPointY("127.248071");
                            } else if (
                                shigungu === "공주시"
                            ) {
                                setPointX("36.4469216");
                                setPointY("127.119349");
                            } else if (
                                shigungu === "금산군"
                            ) {
                                setPointX("36.108712");
                                setPointY("127.488452");
                            } else if (
                                shigungu === "논산시"
                            ) {
                                setPointX("36.1868685");
                                setPointY("127.098825");
                            } else if (
                                shigungu === "당진시"
                            ) {
                                setPointX("36.8895754");
                                setPointY("126.645216");
                            } else if (
                                shigungu === "보령시"
                            ) {
                                setPointX("36.3335376");
                                setPointY("126.612168");
                            } else if (
                                shigungu === "부여군"
                            ) {
                                setPointX("36.2754065");
                                setPointY("126.909986");
                            } else if (
                                shigungu === "서산시"
                            ) {
                                setPointX("36.7848916");
                                setPointY("126.450526");
                            } else if (
                                shigungu === "서천군"
                            ) {
                                setPointX("36.0803902");
                                setPointY("126.691489");
                            } else if (
                                shigungu === "아산시"
                            ) {
                                setPointX("36.7895734");
                                setPointY("127.002481");
                            } else if (
                                shigungu === "예산군"
                            ) {
                                setPointX("36.6806694");
                                setPointY("126.844742");
                            } else if (
                                shigungu === "천안시"
                            ) {
                                setPointX("36.8148681");
                                setPointY("127.11405");
                            } else if (
                                shigungu === "청양군"
                            ) {
                                setPointX("36.458916");
                                setPointY("126.802061");
                            } else if (
                                shigungu === "태안군"
                            ) {
                                setPointX("36.7454506");
                                setPointY("126.298349");
                            } else {
                                setPointX("36.6010835");
                                setPointY("126.660171");
                            }
                        }
                        else if (metro === "전북") {
                            if (
                                shigungu === "고창군"
                            ) {
                                setPointX("35.4356009");
                                setPointY("126.702052");
                            } else if (
                                shigungu === "군산시"
                            ) {
                                setPointX("35.9675127");
                                setPointY("126.73683");
                            } else if (
                                shigungu === "김제시"
                            ) {
                                setPointX("35.8038341");
                                setPointY("126.880547");
                            } else if (
                                shigungu === "남원시"
                            ) {
                                setPointX("35.4162016");
                                setPointY("127.390475");
                            } else if (
                                shigungu === "무주군"
                            ) {
                                setPointX("36.0071053");
                                setPointY("127.660945");
                            } else if (
                                shigungu === "부안군"
                            ) {
                                setPointX("35.7316575");
                                setPointY("126.733423");
                            } else if (
                                shigungu === "순창군"
                            ) {
                                setPointX("35.3742178");
                                setPointY("127.137961");
                            } else if (
                                shigungu === "완주군"
                            ) {
                                setPointX("35.9042675");
                                setPointY("127.162273");
                            } else if (
                                shigungu === "익산시"
                            ) {
                                setPointX("35.9480774");
                                setPointY("126.957725");
                            } else if (
                                shigungu === "임실군"
                            ) {
                                setPointX("35.6177092");
                                setPointY("127.289117");
                            } else if (
                                shigungu === "장수군"
                            ) {
                                setPointX("35.6475115");
                                setPointY("127.521247");
                            } else if (
                                shigungu === "전주시"
                            ) {
                                setPointX("35.8243093");
                                setPointY("127.147824");
                            } else if (
                                shigungu === "정읍시"
                            ) {
                                setPointX("35.5700345");
                                setPointY("126.856189");
                            } else {
                                setPointX("35.7916765");
                                setPointY("127.424796");
                            }
                        }
                        else if (metro === "전남") {
                            if (
                                shigungu === "강진군"
                            ) {
                                setPointX("34.6416469");
                                setPointY("126.767038");
                            } else if (
                                shigungu === "고흥군"
                            ) {
                                setPointX("34.6046683");
                                setPointY("127.275756");
                            } else if (
                                shigungu === "곡성군"
                            ) {
                                setPointX("35.2817771");
                                setPointY("127.291596");
                            } else if (
                                shigungu === "광양시"
                            ) {
                                setPointX("34.9404348");
                                setPointY("127.695977");
                            } else if (
                                shigungu === "구례군"
                            ) {
                                setPointX("35.2024134");
                                setPointY("127.463181");
                            } else if (
                                shigungu === "나주시"
                            ) {
                                setPointX("35.0160761");
                                setPointY("126.711105");
                            } else if (
                                shigungu === "담양군"
                            ) {
                                setPointX("35.3210259");
                                setPointY("126.988286");
                            } else if (
                                shigungu === "목포시"
                            ) {
                                setPointX("34.8116712");
                                setPointY("126.392332");
                            } else if (
                                shigungu === "무안군"
                            ) {
                                setPointX("34.9905668");
                                setPointY("126.481414");
                            } else if (
                                shigungu === "보성군"
                            ) {
                                setPointX("34.7713903");
                                setPointY("127.079938");
                            } else if (
                                shigungu === "순천시"
                            ) {
                                setPointX("34.950534");
                                setPointY("127.487393");
                            } else if (
                                shigungu === "신안군"
                            ) {
                                setPointX("34.8334082");
                                setPointY("126.351407");
                            } else if (
                                shigungu === "여수시"
                            ) {
                                setPointX("34.7606175");
                                setPointY("127.662258");
                            } else if (
                                shigungu === "영광군"
                            ) {
                                setPointX("35.2772416");
                                setPointY("126.512123");
                            } else if (
                                shigungu === "영암군"
                            ) {
                                setPointX("34.8003397");
                                setPointY("126.697079");
                            } else if (
                                shigungu === "완도군"
                            ) {
                                setPointX("34.3108802");
                                setPointY("126.755012");
                            } else if (
                                shigungu === "장성군"
                            ) {
                                setPointX("35.3017151");
                                setPointY("126.784677");
                            } else if (
                                shigungu === "장흥군"
                            ) {
                                setPointX("34.6815166");
                                setPointY("126.907147");
                            } else if (
                                shigungu === "진도군"
                            ) {
                                setPointX("34.4865435");
                                setPointY("126.263601");
                            } else if (
                                shigungu === "함평군"
                            ) {
                                setPointX("35.0652175");
                                setPointY("126.516209");
                            } else if (
                                shigungu === "해남군"
                            ) {
                                setPointX("34.5734482");
                                setPointY("126.599151");
                            } else {
                                setPointX("35.0643484");
                                setPointY("126.986507");
                            }
                        }
                        else if (metro === "경북") {
                            if (
                                shigungu === "경산시"
                            ) {
                                setPointX("35.8253176");
                                setPointY("128.740357");
                            } else if (
                                shigungu === "경주시"
                            ) {
                                setPointX("35.8560627");
                                setPointY("129.224951");
                            } else if (
                                shigungu === "고령군"
                            ) {
                                setPointX("35.725813");
                                setPointY("128.262799");
                            } else if (
                                shigungu === "구미시"
                            ) {
                                setPointX("36.1192869");
                                setPointY("128.344434");
                            } else if (
                                shigungu === "군위군"
                            ) {
                                setPointX("36.2426062");
                                setPointY("128.572947");
                            } else if (
                                shigungu === "김천시"
                            ) {
                                setPointX("36.1398359");
                                setPointY("128.113824");
                            } else if (
                                shigungu === "문경시"
                            ) {
                                setPointX("36.5862413");
                                setPointY("128.186903");
                            } else if (
                                shigungu === "봉화군"
                            ) {
                                setPointX("36.8931794");
                                setPointY("36.8931794");
                            } else if (
                                shigungu === "상주시"
                            ) {
                                setPointX("36.4108191");
                                setPointY("128.159036");
                            } else if (
                                shigungu === "성주군"
                            ) {
                                setPointX("35.9192171");
                                setPointY("128.283157");
                            } else if (
                                shigungu === "안동시"
                            ) {
                                setPointX("36.5683133");
                                setPointY("128.729884");
                            } else if (
                                shigungu === "영덕군"
                            ) {
                                setPointX("36.4148847");
                                setPointY("129.365358");
                            } else if (
                                shigungu === "영양군"
                            ) {
                                setPointX("36.6667152");
                                setPointY("129.112493");
                            } else if (
                                shigungu === "영주시"
                            ) {
                                setPointX("36.8058267");
                                setPointY("128.624462");
                            } else if (
                                shigungu === "영천시"
                            ) {
                                setPointX("35.9730582");
                                setPointY("128.938893");
                            } else if (
                                shigungu === "예천군"
                            ) {
                                setPointX("36.6467862");
                                setPointY("128.437324");
                            } else if (
                                shigungu === "울릉군"
                            ) {
                                setPointX("37.4844639");
                                setPointY("130.905647");
                            } else if (
                                shigungu === "울진군"
                            ) {
                                setPointX("36.9930731");
                                setPointY("129.400831");
                            } else if (
                                shigungu === "의성군"
                            ) {
                                setPointX("36.3525549");
                                setPointY("128.696918");
                            } else if (
                                shigungu === "청도군"
                            ) {
                                setPointX("35.6476134");
                                setPointY("128.734763");
                            } else if (
                                shigungu === "청송군"
                            ) {
                                setPointX("36.4362316");
                                setPointY("129.057203");
                            } else if (
                                shigungu === "칠곡군"
                            ) {
                                setPointX("35.995228");
                                setPointY("128.401832");
                            } else {
                                setPointX("36.0191529");
                                setPointY("129.34345");
                            }
                        }
                        else if (metro === "경남") {
                            if (
                                shigungu === "거제시"
                            ) {
                                setPointX("34.8807806");
                                setPointY("128.621233");
                            } else if (
                                shigungu === "거창군"
                            ) {
                                setPointX("35.6866668");
                                setPointY("127.909539");
                            } else if (
                                shigungu === "고성군"
                            ) {
                                setPointX("34.9730865");
                                setPointY("128.322158");
                            } else if (
                                shigungu === "김해시"
                            ) {
                                setPointX("35.2284018");
                                setPointY("128.889138");
                            } else if (
                                shigungu === "남해군"
                            ) {
                                setPointX("34.8379999");
                                setPointY("127.892795");
                            } else if (
                                shigungu === "밀양시"
                            ) {
                                setPointX("35.5034387");
                                setPointY("128.746537");
                            } else if (
                                shigungu === "사천시"
                            ) {
                                setPointX("35.0034842");
                                setPointY("128.064562");
                            } else if (
                                shigungu === "산청군"
                            ) {
                                setPointX("35.4155725");
                                setPointY("127.873502");
                            } else if (
                                shigungu === "양산시"
                            ) {
                                setPointX("35.3347938");
                                setPointY("129.038443");
                            } else if (
                                shigungu === "의령군"
                            ) {
                                setPointX("35.3220474");
                                setPointY("128.261698");
                            } else if (
                                shigungu === "진주시"
                            ) {
                                setPointX("35.1803143");
                                setPointY("128.10762");
                            } else if (
                                shigungu === "창녕군"
                            ) {
                                setPointX("35.5445551");
                                setPointY("128.491594");
                            } else if (
                                shigungu === "창원시"
                            ) {
                                setPointX("35.2283472");
                                setPointY("128.681952");
                            } else if (
                                shigungu === "통영시"
                            ) {
                                setPointX("34.8542723");
                                setPointY("128.433202");
                            } else if (
                                shigungu === "하동군"
                            ) {
                                setPointX("35.0673555");
                                setPointY("127.751799");
                            } else if (
                                shigungu === "함안군"
                            ) {
                                setPointX("35.2724932");
                                setPointY("35.2724932");
                            } else if (
                                shigungu === "함양군"
                            ) {
                                setPointX("35.5206316");
                                setPointY("127.725369");
                            } else {
                                setPointX("35.5666135");
                                setPointY("128.166147");
                            }
                        }
                        else if (metro === "제주") {
                            if (
                                shigungu === "서귀포"
                            ) {
                                setPointX("33.2540451");
                                setPointY("126.560426");
                            } else {
                                setPointX("33.4998064");
                                setPointY("126.531409");
                            }
                        }
                    }
                    else {
                        setPointX(lat);
                        setPointY(lng);
                    }
                }

                const mapOptions = {
                    center: new window.naver.maps.LatLng(pointX, pointY),
                    zoom: 17,
                };
                const map = new window.naver.maps.Map(mapElement.current, mapOptions);

                /* 마커 추가 */
                const marker = new window.naver.maps.Marker({
                    position: new window.naver.maps.LatLng(pointX, pointY),
                    map: map,
                });

                /* 마커에 인포윈도우 추가
                // const infoWindow = new window.naver.maps.InfoWindow({
                //   content: '<div style="padding:10px;">서울특별시</div>',
                // });*/

                /* 인포윈도우 열기
                window.naver.maps.Event.addListener(marker, 'click', () => {
                  infoWindow.open(map, marker);
                });*/
            }
        };
        document.head.appendChild(script);
    }, [metro, pointX]);

    return <div ref={mapElement} style={{ width: '100%', height: '100%' }} />;
};

export default NaverMap;