"use client"


import axios from "axios";

const url = "https://www.scrapmk.com/api";


/* 명함정보 */
interface Businesscard {
    logo : string;
    nicknaem : string;
    name : string;
    position : string;
    bizName : string;
    metro : string;
    shigungu : string;
    detail : string;
    cell : string;
    tel : string;
    email : string;
}

export const BusinesscardInfo = async (pk:number | undefined): Promise<Businesscard> => {
  const result = await axios.get(`${url}/user/userBusineeCard/${pk}/`);
  return result.data; 
};

export type {
    Businesscard  
}

