"use client"

import ScrapMetal from "../../ScrapMetal/[slug]/page"

type PageParams = {
  slug: string;
}

export default function Tire({ params  }: { params: string} ) {

  return (
    <>
     <ScrapMetal params = {params} category="타이어" api_category = "tire" />
    </>
  )
}