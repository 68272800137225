import React from 'react';
import './Common/globals.css';
import './Common/page.css';
import { Link } from 'react-router-dom';
import Header from './Common/Header';
import Footer from './Common/Footer';
import { useNavigate } from 'react-router-dom';

function Main({ title, subtitle, subtitleSecond, contentFirst}: { title: string, subtitle: string, subtitleSecond: string, contentFirst: string }) {
  
  const navigate = useNavigate()
  
  return (
    <div className='Adtotal'>
      <div className='Adtitle'>{title}</div>
      <div className='AdFirst'>{subtitle}</div>
      <div className='AdFirst' style={{ marginTop:"0px" }}>{subtitleSecond}</div>
      <div className='AdSecond'>{contentFirst}</div>
      <Link to='https://play.google.com/store/apps/details?id=com.scrapmarket.scrapmarket' className="MainAdloacte">                       
        <img src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Common/PlayStore_Logo_Text_Include.png" alt='playstoe' className='MainAdloactePlayStore' />
        <img src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Common/Common_Playstore_Download.png" alt='Download' width={32} height={32} />
      </Link>
    </div>
  )
}



function App() {
  return (
    <>
      <Header />
      <div className="TotalBox">

        {/* 메인 이미지 */}
        <div className="MainImgBox">
          <div className="MainImgText">세상의 모든 자원 플랫폼 앱</div>
          <div className="MainImgTextSecond">스크랩마켓</div>
          <img
            className="MainImg"
            src="https://s3.ap-northeast-2.amazonaws.com/scrapmarket-2.0.0/Img/ScrapmarketIntroduceImg.png"
            alt="MainImg"
          />
          <Link to='https://play.google.com/store/apps/details?id=com.scrapmarket.scrapmarket' className="LocateBox" >
            <div className="LocateInnerBox">
              <img
                src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Common/PlayStore_Logo_Text_Include.png"
                width={171}
                height={40}
                alt="Locate"
              />
            </div>
          </Link>
        </div>

        <div className="FirstBox">
          <div className="FirstInnerBox">

            <div className="InnerFirstBox">
              <div className="FirstLeft">
                <img src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Main/Main_Ad_2.png" alt='ad_1' width={280} height={556} />
              </div>
              <div className="FirstRight">
                <Main title="지도검색" subtitle="위치기반" subtitleSecond="지도 검색 기능"
                  contentFirst="지역별 매물을 한눈에 검색해보세요." 
                />
              </div>
            </div>

            <div className="InnerSecondBox">
              <div className="FirstLeft">
                <Main title="시세정보" subtitle="휴대폰 바탕화면" subtitleSecond="시세정보 위젯"
                  contentFirst="휴대폰 바탕화면에서 손쉽게 일일 시세정보를 확인해보세요."
                />
              </div>
              <div className="FirstRight">
                <img src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Main/Main_Ad_1.png" alt='ad_2' width={280} height={556} />
              </div>
            </div>

            <div className="InnerFirstBox">
              <div className="FirstLeft">
                <img src="https://scrapmarket.s3.ap-northeast-2.amazonaws.com/Renewal/Main/Main_Ad_3.png" alt='ad_3' width={280} height={556} />
              </div>
              <div className="FirstRight">
                <Main title="채팅과 명함" subtitle="채팅과" subtitleSecond="명함(프로필) 전송"
                  contentFirst="채팅으로 즉시 소통하고 명함으로 프로필을 보내보세요."
                />
              </div>
            </div>


          </div>
        </div>

      </div>
      <Footer />
    </>
  );
}

export default App;
